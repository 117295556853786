import { useEffect, useState } from 'react';

import {
  API_BASE_URL,
  API_LOGIN_URL,
  API_LOGOUT_URL,
} from '@/old/config/api_routes';
import { loading } from '@/old/state/actions/Loaders/loader_action';
import { logout } from '@/old/state/actions/Login/login_actions';
import { store } from '@/old/state/store';

import { USER_TIMEZONE } from './date-formatters';
import { reportToSentry } from './sentry';

type FetchUrl = Parameters<typeof fetch>[0];
type FetchOptions = Parameters<typeof fetch>[1];

/**
 * Status codes that are considered OK to be retried.
 * These status codes indicate temporary issues that may be resolved by retrying the request.
 * @remarks
 * The following status codes are included:
 * - 408 (Request Timeout): The server timed out waiting for the request.
 * - 425 (Too Early): The server is unwilling to risk processing a request that might be replayed.
 * - 429 (Too Many Requests): The client has sent too many requests in a given amount of time.
 * - 500 (Internal Server Error): An unexpected error occurred on the server.
 * - 502 (Bad Gateway): The server received an invalid response from an upstream server.
 * - 503 (Service Unavailable): The server is temporarily unable to handle the request.
 * - 504 (Gateway Timeout): The server did not receive a timely response from an upstream server.
 */
const RETRY_STATUS_CODES = [408, 425, 429, 500, 502, 503, 504];

/**
 * Status codes that indicate unauthorized access.
 * These status codes indicate that the client is not authorized to access the requested resource.
 * @remarks
 * The following status codes are included:
 * - 401 (Unauthorized): The request requires user authentication.
 * - 403 (Forbidden): The server understood the request, but refuses to authorize it.
 */
export const UNAUTHORIZED_STATUS_CODES = [401, 403];

const DEFAULT_RETRIES = 3;
const DEFAULT_BACKOFF_MS = 300;

function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export class ResponseError extends Error {
  response: Response;

  constructor(response: Response) {
    super(`HTTP error! status: ${response.status}`);
    this.name = 'ResponseError';
    this.response = response;
  }
}

export class UnauthorizedError extends Error {
  constructor() {
    super('Unauthorized access');
    this.name = 'UnauthorizedError';
  }
}

export async function reportSentryError(
  input: FetchUrl,
  immutableError: Error,
) {
  if (!(immutableError instanceof ResponseError)) {
    reportToSentry(immutableError, { uri: input, data: 'No response data' });

    return;
  }

  const error = new ResponseError(immutableError.response);

  const { status, statusText, headers } = error.response;

  const data = await error.response.clone().text();

  const context = {
    uri: input,
    data,
    status,
    headers,
    statusText,
  };

  error.name = `Request Error @ ${input}`;
  error.message = `[${status}] ${JSON.stringify(data)}`;

  reportToSentry(error, context);
}

export const inputIncludes = (input: FetchUrl, subString: string) =>
  input instanceof Request
    ? input.url.includes(subString)
    : input instanceof URL
      ? input.href.includes(subString)
      : input.includes(subString);

export async function fetchWithRetry<T>(
  input: FetchUrl,
  options?: FetchOptions,
  retries = DEFAULT_RETRIES,
  backoffMs = DEFAULT_BACKOFF_MS,
) {
  try {
    store.dispatch(loading(true));

    const token = store.getState().Session.token;

    const headers = new Headers(options?.headers);

    if (inputIncludes(input, API_BASE_URL)) {
      headers.set('Django-Timezone', `${USER_TIMEZONE}`);

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
    }

    const response = await fetch(input, {
      ...options,
      headers,
    });

    if (!response.ok) {
      throw new ResponseError(response);
    }

    const contentType =
      response.headers.get('Content-Type')?.toLowerCase() ?? '';

    if (contentType.includes('application/json')) {
      return (await response.json()) as T;
    }

    if (contentType.includes('text/plain')) {
      return (await response.text()) as T;
    }

    return response.body as T;
  } catch (error) {
    if (!(error instanceof ResponseError)) {
      throw error;
    }

    // Retry only if Server Error or status code is in RETRY_STATUS_CODES
    if (retries > 0 && RETRY_STATUS_CODES.includes(error.response.status)) {
      await sleep(backoffMs);

      return fetchWithRetry(input, options, retries - 1, backoffMs * 2);
    }

    // Logout user if unauthorized access, ignore if logout request
    if (
      UNAUTHORIZED_STATUS_CODES.includes(error.response.status) &&
      inputIncludes(input, API_BASE_URL) &&
      !inputIncludes(input, API_LOGOUT_URL) &&
      !inputIncludes(input, API_LOGIN_URL)
    ) {
      store.dispatch(logout(true));
      throw new UnauthorizedError();
    }

    // Report error to Sentry if not unauthorized access, regardless if 3rd party
    if (!UNAUTHORIZED_STATUS_CODES.includes(error.response.status)) {
      reportSentryError(input, error as Error);
    }

    throw error;
  } finally {
    store.dispatch(loading(false));
  }
}

export async function sendWithRetry<T>(
  input: FetchUrl,
  body: unknown,
  options?: FetchOptions,
  retries = DEFAULT_RETRIES,
  backoffMs = DEFAULT_BACKOFF_MS,
) {
  return fetchWithRetry<T>(
    input,
    {
      method: 'POST',
      body: JSON.stringify(body),
      ...options,
      headers: {
        'Content-Type': 'application/json',
        ...options?.headers,
      },
    },
    retries,
    backoffMs,
  );
}

export function useFetch<T>(
  input: FetchUrl,
  options?: FetchOptions,
  retry = DEFAULT_RETRIES,
  backoffMs = DEFAULT_BACKOFF_MS,
) {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<unknown | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchWithRetry<T>(input, options, retry, backoffMs);
        setData(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [backoffMs, input, options, retry]);

  return { data, loading, error };
}
