/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const TypeAc3Enum = {
  Connect: 'CONNECT',
  Engage: 'ENGAGE',
  Support: 'SUPPORT',
} as const;
export type TypeAc3Enum = (typeof TypeAc3Enum)[keyof typeof TypeAc3Enum];

export function TypeAc3EnumFromJSON(json: any): TypeAc3Enum {
  return TypeAc3EnumFromJSONTyped(json, false);
}

export function TypeAc3EnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TypeAc3Enum {
  return json as TypeAc3Enum;
}

export function TypeAc3EnumToJSON(value?: TypeAc3Enum | null): any {
  return value as any;
}
