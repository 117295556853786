import { UserSerializerSlim } from '@/schema';

import { Avatar, AvatarFallback } from './ui/avatar';
import { Tooltip, TooltipContent, TooltipTrigger } from './ui/tooltip';

const getUserLabel = (user: UserSerializerSlim, showClinic?: boolean) => {
  const isInactive = !user.isActive;

  return (
    <div className="flex gap-1">
      <span>{user.fullName}</span>
      {showClinic && <span>({user.defaultClinic})</span>}
      {isInactive && <span>(Inactive)</span>}
    </div>
  );
};

interface TooltipInitialsAvatarProps {
  user: UserSerializerSlim;
  isPrimary?: boolean;
}

export const TooltipInitialsAvatar = (props: TooltipInitialsAvatarProps) => {
  const { user, isPrimary } = props;

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Avatar>
          <AvatarFallback>
            {user.firstName?.[0]}
            {user.lastName?.[0]}
          </AvatarFallback>
        </Avatar>
      </TooltipTrigger>

      <TooltipContent className="flex gap-2">
        {isPrimary && '(Primary) '}
        {getUserLabel(user, true)}
      </TooltipContent>
    </Tooltip>
  );
};

interface TooltipGroupedAvatarProps {
  users: UserSerializerSlim[];
}

export const TooltipGroupedAvatar = (props: TooltipGroupedAvatarProps) => {
  const { users } = props;

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Avatar>
          <AvatarFallback>+{users.length}</AvatarFallback>
        </Avatar>
      </TooltipTrigger>

      <TooltipContent>
        {users.map((user) => (
          <div key={user.id}>{getUserLabel(user, true)}</div>
        ))}
      </TooltipContent>
    </Tooltip>
  );
};
