/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `PATIENT_SMS_OPT_OUT` - Patient SMS Opt Out
 * * `PATIENT_SMS_UNREACHABLE` - Patient SMS Unreachable
 * * `PROVIDER_REQUEST` - Provider Request
 * * `PROVIDER_ASSIGN_NEW_WORKFLOW` - Provider Assign New Workflow
 * @export
 */
export const UnenrolledReasonEnum = {
  PatientSmsOptOut: 'PATIENT_SMS_OPT_OUT',
  PatientSmsUnreachable: 'PATIENT_SMS_UNREACHABLE',
  ProviderRequest: 'PROVIDER_REQUEST',
  ProviderAssignNewWorkflow: 'PROVIDER_ASSIGN_NEW_WORKFLOW',
} as const;
export type UnenrolledReasonEnum =
  (typeof UnenrolledReasonEnum)[keyof typeof UnenrolledReasonEnum];

export function UnenrolledReasonEnumFromJSON(json: any): UnenrolledReasonEnum {
  return UnenrolledReasonEnumFromJSONTyped(json, false);
}

export function UnenrolledReasonEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UnenrolledReasonEnum {
  return json as UnenrolledReasonEnum;
}

export function UnenrolledReasonEnumToJSON(
  value?: UnenrolledReasonEnum | null,
): any {
  return value as any;
}
