/* tslint:disable */

/* eslint-disable */

/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { ProviderPatientRelationship } from './ProviderPatientRelationship';
import {
  ProviderPatientRelationshipFromJSON,
  ProviderPatientRelationshipFromJSONTyped,
  ProviderPatientRelationshipToJSON,
} from './ProviderPatientRelationship';
import type { ProviderSerializerSlim } from './ProviderSerializerSlim';
import {
  ProviderSerializerSlimFromJSON,
  ProviderSerializerSlimFromJSONTyped,
  ProviderSerializerSlimToJSON,
} from './ProviderSerializerSlim';
import type { RTMCycle } from './RTMCycle';
import {
  RTMCycleFromJSON,
  RTMCycleFromJSONTyped,
  RTMCycleToJSON,
} from './RTMCycle';
import type { RTMEnrollment } from './RTMEnrollment';
import {
  RTMEnrollmentFromJSON,
  RTMEnrollmentFromJSONTyped,
  RTMEnrollmentToJSON,
} from './RTMEnrollment';
import type { User } from './User';
import { UserFromJSON, UserFromJSONTyped, UserToJSON } from './User';
import type { UserSerializerFullName } from './UserSerializerFullName';
import {
  UserSerializerFullNameFromJSON,
  UserSerializerFullNameFromJSONTyped,
  UserSerializerFullNameToJSON,
} from './UserSerializerFullName';

/**
 *
 * @export
 * @interface Patient
 */
export interface Patient {
  /**
   *
   * @type {User}
   * @memberof Patient
   */
  readonly user: User;
  /**
   *
   * @type {number}
   * @memberof Patient
   */
  readonly currentIntlogSecs: number;
  /**
   *
   * @type {boolean}
   * @memberof Patient
   */
  readonly hasSynchronousAction: boolean;
  /**
   *
   * @type {string}
   * @memberof Patient
   */
  readonly sbChannelUrl: string | null;
  /**
   *
   * @type {Array<ProviderPatientRelationship>}
   * @memberof Patient
   */
  readonly providerRelationships: Array<ProviderPatientRelationship>;
  /**
   *
   * @type {ProviderSerializerSlim}
   * @memberof Patient
   */
  readonly primaryProvider: ProviderSerializerSlim;
  /**
   *
   * @type {RTMCycle}
   * @memberof Patient
   */
  readonly currentRtmcycle: RTMCycle | null;
  /**
   *
   * @type {RTMEnrollment}
   * @memberof Patient
   */
  readonly latestRtmenrollment: RTMEnrollment | null;
  /**
   *
   * @type {boolean}
   * @memberof Patient
   */
  readonly isCurrentlyEnrolled: boolean;
  /**
   *
   * @type {Date}
   * @memberof Patient
   */
  readonly currentRtmcycleEndDate: Date;
  /**
   *
   * @type {number}
   * @memberof Patient
   */
  readonly currentRtmcycleResponsesCount: number;
  /**
   *
   * @type {string}
   * @memberof Patient
   */
  note?: string;
  /**
   *
   * @type {Date}
   * @memberof Patient
   */
  readonly noteUpdated: Date;
  /**
   *
   * @type {UserSerializerFullName}
   * @memberof Patient
   */
  readonly noteUpdatedBy: UserSerializerFullName;
}

/**
 * Check if a given object implements the Patient interface.
 */
export function instanceOfPatient(value: object): boolean {
  if (!('user' in value)) return false;
  if (!('currentIntlogSecs' in value)) return false;
  if (!('hasSynchronousAction' in value)) return false;
  if (!('sbChannelUrl' in value)) return false;
  if (!('providerRelationships' in value)) return false;
  if (!('primaryProvider' in value)) return false;
  if (!('currentRtmcycle' in value)) return false;
  if (!('latestRtmenrollment' in value)) return false;
  if (!('isCurrentlyEnrolled' in value)) return false;
  if (!('currentRtmcycleEndDate' in value)) return false;
  if (!('currentRtmcycleResponsesCount' in value)) return false;
  if (!('noteUpdated' in value)) return false;
  if (!('noteUpdatedBy' in value)) return false;
  return true;
}

export function PatientFromJSON(json: any): Patient {
  return PatientFromJSONTyped(json, false);
}

export function PatientFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Patient {
  if (json == null) {
    return json;
  }
  return {
    user: UserFromJSON(json['user']),
    currentIntlogSecs: json['current_intlog_secs'],
    hasSynchronousAction: json['has_synchronous_action'],
    sbChannelUrl: json['sb_channel_url'],
    providerRelationships: (json['provider_relationships'] as Array<any>).map(
      ProviderPatientRelationshipFromJSON,
    ),
    primaryProvider: ProviderSerializerSlimFromJSON(json['primary_provider']),
    currentRtmcycle: RTMCycleFromJSON(json['current_rtmcycle']),
    latestRtmenrollment: RTMEnrollmentFromJSON(json['latest_rtmenrollment']),
    isCurrentlyEnrolled: json['is_currently_enrolled'],
    currentRtmcycleEndDate: new Date(json['current_rtmcycle_end_date']),
    currentRtmcycleResponsesCount: json['current_rtmcycle_responses_count'],
    note: json['note'] == null ? undefined : json['note'],
    noteUpdated: new Date(json['note_updated']),
    noteUpdatedBy: UserSerializerFullNameFromJSON(json['note_updated_by']),
  };
}

export function PatientToJSON(value?: Patient | null): any {
  if (value == null) {
    return value;
  }
  return {
    note: value['note'],
  };
}
