/* tslint:disable */

/* eslint-disable */

/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { Company } from './Company';
import {
  CompanyFromJSON,
  CompanyFromJSONTyped,
  CompanyToJSON,
} from './Company';
import type { Provider } from './Provider';
import {
  ProviderFromJSON,
  ProviderFromJSONTyped,
  ProviderToJSON,
} from './Provider';

/**
 *
 * @export
 * @interface ProviderMetadata
 */
export interface ProviderMetadata {
  /**
   *
   * @type {Company}
   * @memberof ProviderMetadata
   */
  readonly company: Company;
  /**
   *
   * @type {Provider}
   * @memberof ProviderMetadata
   */
  readonly providerMetadata: Provider;
}

/**
 * Check if a given object implements the ProviderMetadata interface.
 */
export function instanceOfProviderMetadata(value: object): boolean {
  if (!('company' in value)) return false;
  if (!('providerMetadata' in value)) return false;
  return true;
}

export function ProviderMetadataFromJSON(json: any): ProviderMetadata {
  return ProviderMetadataFromJSONTyped(json, false);
}

export function ProviderMetadataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ProviderMetadata {
  if (json == null) {
    return json;
  }
  return {
    company: CompanyFromJSON(json['company']),
    providerMetadata: ProviderFromJSON(json['provider_metadata']),
  };
}

export function ProviderMetadataToJSON(value?: ProviderMetadata | null): any {
  if (value == null) {
    return value;
  }
  return {};
}
