/* tslint:disable */

/* eslint-disable */

/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { LanguagesEnum } from './LanguagesEnum';
import {
  LanguagesEnumFromJSON,
  LanguagesEnumFromJSONTyped,
  LanguagesEnumToJSON,
} from './LanguagesEnum';

/**
 *
 * @export
 * @interface Workflow
 */
export interface Workflow {
  /**
   *
   * @type {number}
   * @memberof Workflow
   */
  readonly id: number;
  /**
   *
   * @type {string}
   * @memberof Workflow
   */
  rtmFlowTag?: string;
  /**
   *
   * @type {string}
   * @memberof Workflow
   */
  name?: string;
  /**
   * a list of ISO-3166-1 (alpha 2) codes
   * @type {Array<LanguagesEnum>}
   * @memberof Workflow
   */
  languages?: Array<LanguagesEnum>;
}

/**
 * Check if a given object implements the Workflow interface.
 */
export function instanceOfWorkflow(value: object): boolean {
  if (!('id' in value)) return false;
  return true;
}

export function WorkflowFromJSON(json: any): Workflow {
  return WorkflowFromJSONTyped(json, false);
}

export function WorkflowFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Workflow {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'],
    rtmFlowTag: json['rtm_flow_tag'] == null ? undefined : json['rtm_flow_tag'],
    name: json['name'] == null ? undefined : json['name'],
    languages:
      json['languages'] == null
        ? undefined
        : (json['languages'] as Array<any>).map(LanguagesEnumFromJSON),
  };
}

export function WorkflowToJSON(value?: Workflow | null): any {
  if (value == null) {
    return value;
  }
  return {
    rtm_flow_tag: value['rtmFlowTag'],
    name: value['name'],
    languages:
      value['languages'] == null
        ? undefined
        : (value['languages'] as Array<any>).map(LanguagesEnumToJSON),
  };
}
