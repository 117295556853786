import SendbirdChat, { ConnectionState } from '@sendbird/chat';
import {
  GroupChannelModule,
  SendbirdGroupChat,
} from '@sendbird/chat/groupChannel';
import { create } from 'zustand';

export const sendbirdChat = SendbirdChat.init({
  appId: import.meta.env.VITE_SENDBIRD_APP_ID,
  modules: [new GroupChannelModule()],
}) as SendbirdGroupChat;

export interface SendbirdChatState {
  connectionState: ConnectionState;
  connect: (userId: string, sessionToken: string) => void;
  disconnect: () => void;
  setConnectionState: (connectionState: ConnectionState) => void;
}

export const useSendbirdChatState = create<SendbirdChatState>()((set) => ({
  connectionState: ConnectionState.CLOSED,
  connect: async (userId: string, sessionToken: string) => {
    set({ connectionState: ConnectionState.CONNECTING });

    await sendbirdChat.connect(userId, sessionToken);

    set({ connectionState: ConnectionState.OPEN });
  },
  disconnect: () => {
    sendbirdChat.disconnect();
    set({ connectionState: ConnectionState.CLOSED });
  },
  setConnectionState: (connectionState: ConnectionState) =>
    set({ connectionState }),
}));
