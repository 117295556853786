import { useQueryClient } from '@tanstack/react-query';
import { Row, Table } from '@tanstack/react-table';
import { Undo2Icon } from 'lucide-react';
import { toast } from 'sonner';

import { BillingInstance, OperationEnum } from '@/schema';
import { billingApi } from '@/state/api-instances';
import { BillingTab, useBillingState } from '@/state/billing-state';

import { ConfirmationDialog } from '../confirmation-dialog';
import {
  PaginatedTableResponsiveActions,
  ResponsiveActionsTrigger,
} from '../paginated-table-responsive-actions';
import { Tooltip } from '../ui/tooltip';
import { BillingCancelAction } from './billing-cancel-action';
import { JustificationDialog } from './billing-justification-dialog';

interface BillingActionCellProps {
  row: Row<BillingInstance>;
  table: Table<BillingInstance>;
}

export const BillingActionCell = ({ table, row }: BillingActionCellProps) => {
  const tabType = useBillingState((state) => state.selectedTab);
  const queryClient = useQueryClient();

  if (tabType === BillingTab.Pending) {
    return 'This billing is still pending.';
  }

  const unMarkAsBilled = async () => {
    if (tabType !== BillingTab.Billed) {
      return;
    }

    await billingApi.billingBillinginstancesReconcileInBulkCreate({
      billingInstanceBulkBill: {
        uuids: [row.original.uuid],
        operation: 'UNRECONCILE',
      },
    });

    table.setPageIndex(() => 0);
    table
      .getFilteredSelectedRowModel()
      .rows.forEach((row) => row.toggleSelected(false));

    toast.success('Successfully unmarked as billed.');

    await queryClient.resetQueries({
      queryKey: ['billingItems'],
    });
    await queryClient.resetQueries({
      queryKey: ['billingNotificationDot'],
    });
  };

  const undoCancellation = async () => {
    try {
      await billingApi.billingBillinginstancesCancelInBulkCreate({
        billingInstanceBulkCancel: {
          uuids: [row.original.uuid],
          operation: OperationEnum.Uncancel,
        },
      });

      table.setPageIndex(0);

      toast.success('Successfully undid the cancellation.');

      await queryClient.resetQueries({
        queryKey: ['billingItems'],
      });
      await queryClient.resetQueries({
        queryKey: ['billingNotificationDot'],
      });
    } catch (error) {
      toast.error('Failed to undo cancellation.');
      console.error('Undo cancel billing error:', error);
    }
  };

  return (
    <PaginatedTableResponsiveActions>
      {tabType === BillingTab.Billed && (
        <>
          <JustificationDialog isHoverActionItem row={row} />

          <Tooltip>
            <ResponsiveActionsTrigger
              Icon={Undo2Icon}
              text="Undo Mark as Billed"
              onClick={unMarkAsBilled}
            />
          </Tooltip>
        </>
      )}

      {tabType === BillingTab.Ready && (
        <BillingCancelAction
          row={row}
          table={table}
          queryClient={queryClient}
        />
      )}

      {tabType === BillingTab.Cancelled && (
        <Tooltip>
          <ConfirmationDialog
            title="Undo Cancellation?"
            description="This will move this card back to the 'ready to bill' tab."
            onConfirm={undoCancellation}
          >
            <ResponsiveActionsTrigger
              Icon={Undo2Icon}
              text="Undo Cancellation"
            />
          </ConfirmationDialog>
        </Tooltip>
      )}
    </PaginatedTableResponsiveActions>
  );
};
