import { Table } from '@tanstack/react-table';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { BillingInstance } from '@/schema';

export const BillingTab = {
  Pending: 'pending',
  Ready: 'ready',
  Billed: 'billed',
  Cancelled: 'cancelled',
} as const;

export type BillingTabEnum = (typeof BillingTab)[keyof typeof BillingTab];

const BillingCountKeys = {
  [BillingTab.Pending]: 'pendingCount',
  [BillingTab.Ready]: 'readyCount',
  [BillingTab.Billed]: 'billedCount',
  [BillingTab.Cancelled]: 'cancelledCount',
} as const;

interface BillingState {
  selectedTab: BillingTabEnum;
  readyTableInstance?: Table<BillingInstance>;
  [BillingCountKeys.pending]: number;
  [BillingCountKeys.ready]: number;
  [BillingCountKeys.billed]: number;
  [BillingCountKeys.cancelled]: number;
  setBillingCount(tab: BillingTabEnum, count: number): void;
  setSelectedTab(tab: BillingTabEnum): void;
  setReadyTableInstance(table: Table<BillingInstance>): void;
}

export const useBillingState = create<BillingState>()(
  devtools(
    persist(
      (set) =>
        ({
          selectedTab: BillingTab.Ready,
          [BillingCountKeys.pending]: 0,
          [BillingCountKeys.ready]: 0,
          [BillingCountKeys.billed]: 0,
          [BillingCountKeys.cancelled]: 0,
          setSelectedTab: (selectedTab) => set({ selectedTab }),
          setBillingCount: (selectedTab, count) =>
            set({ [BillingCountKeys[selectedTab]]: count }),
          setReadyTableInstance: (readyTableInstance) =>
            set({ readyTableInstance }),
        }) as BillingState,
      { name: 'billing-state' },
    ),
    { name: 'billing-state' },
  ),
);
