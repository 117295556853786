/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `CANCEL` - CANCEL
 * * `UNCANCEL` - UNCANCEL
 * * `RECONCILE` - RECONCILE
 * * `UNRECONCILE` - UNRECONCILE
 * @export
 */
export const OperationEnum = {
  Cancel: 'CANCEL',
  Uncancel: 'UNCANCEL',
  Reconcile: 'RECONCILE',
  Unreconcile: 'UNRECONCILE',
} as const;
export type OperationEnum = (typeof OperationEnum)[keyof typeof OperationEnum];

export function OperationEnumFromJSON(json: any): OperationEnum {
  return OperationEnumFromJSONTyped(json, false);
}

export function OperationEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): OperationEnum {
  return json as OperationEnum;
}

export function OperationEnumToJSON(value?: OperationEnum | null): any {
  return value as any;
}
