/* tslint:disable */

/* eslint-disable */

/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { ProviderPatientRelationshipStatusEnum } from './ProviderPatientRelationshipStatusEnum';
import {
  ProviderPatientRelationshipStatusEnumFromJSON,
  ProviderPatientRelationshipStatusEnumFromJSONTyped,
  ProviderPatientRelationshipStatusEnumToJSON,
} from './ProviderPatientRelationshipStatusEnum';
import type { ProviderSerializerSlim } from './ProviderSerializerSlim';
import {
  ProviderSerializerSlimFromJSON,
  ProviderSerializerSlimFromJSONTyped,
  ProviderSerializerSlimToJSON,
} from './ProviderSerializerSlim';

/**
 *
 * @export
 * @interface ProviderPatientRelationship
 */
export interface ProviderPatientRelationship {
  /**
   *
   * @type {boolean}
   * @memberof ProviderPatientRelationship
   */
  isPrimary: boolean;
  /**
   *
   * @type {ProviderSerializerSlim}
   * @memberof ProviderPatientRelationship
   */
  readonly provider: ProviderSerializerSlim;
  /**
   *
   * @type {ProviderPatientRelationshipStatusEnum}
   * @memberof ProviderPatientRelationship
   */
  status?: ProviderPatientRelationshipStatusEnum;
}

/**
 * Check if a given object implements the ProviderPatientRelationship interface.
 */
export function instanceOfProviderPatientRelationship(value: object): boolean {
  if (!('isPrimary' in value)) return false;
  if (!('provider' in value)) return false;
  return true;
}

export function ProviderPatientRelationshipFromJSON(
  json: any,
): ProviderPatientRelationship {
  return ProviderPatientRelationshipFromJSONTyped(json, false);
}

export function ProviderPatientRelationshipFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ProviderPatientRelationship {
  if (json == null) {
    return json;
  }
  return {
    isPrimary: json['is_primary'],
    provider: ProviderSerializerSlimFromJSON(json['provider']),
    status:
      json['status'] == null
        ? undefined
        : ProviderPatientRelationshipStatusEnumFromJSON(json['status']),
  };
}

export function ProviderPatientRelationshipToJSON(
  value?: ProviderPatientRelationship | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    is_primary: value['isPrimary'],
    status: ProviderPatientRelationshipStatusEnumToJSON(value['status']),
  };
}
