/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `POSITIVE` - Positive
 * * `NEGATIVE` - Negative
 * @export
 */
export const FeedbackTypeEnum = {
  Positive: 'POSITIVE',
  Negative: 'NEGATIVE',
} as const;
export type FeedbackTypeEnum =
  (typeof FeedbackTypeEnum)[keyof typeof FeedbackTypeEnum];

export function FeedbackTypeEnumFromJSON(json: any): FeedbackTypeEnum {
  return FeedbackTypeEnumFromJSONTyped(json, false);
}

export function FeedbackTypeEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): FeedbackTypeEnum {
  return json as FeedbackTypeEnum;
}

export function FeedbackTypeEnumToJSON(value?: FeedbackTypeEnum | null): any {
  return value as any;
}
