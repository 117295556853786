import { OnChangeFn, PaginationState } from '@tanstack/react-table';
import { useCallback, useEffect, useState } from 'react';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

export interface TableState {
  pageSize: number;
  setPageSize: (pageSize: number) => void;
}

export const useTableState = create<TableState>()(
  devtools(
    persist(
      (set) =>
        ({
          pageSize: 10,
          setPageSize: (pageSize) => set({ pageSize }),
        }) as TableState,
      { name: 'table-state' },
    ),
    { name: 'table-state' },
  ),
);

export const usePaginationState = (): {
  pagination: PaginationState;
  setPagination: OnChangeFn<PaginationState>;
} => {
  const { pageSize, setPageSize } = useTableState();

  const [pagination, setPaginationState] = useState<PaginationState>({
    pageIndex: 0,
    pageSize,
  });

  const setPagination: OnChangeFn<PaginationState> = useCallback(
    (newPagination) => {
      setPaginationState((v) => {
        const updatedPagination =
          typeof newPagination === 'function'
            ? newPagination(v)
            : newPagination;

        if (updatedPagination.pageSize !== v.pageSize) {
          setPageSize(updatedPagination.pageSize);
        }

        return updatedPagination;
      });
    },
    [setPageSize, setPaginationState],
  );

  useEffect(() => {
    setPaginationState({ pageIndex: 0, pageSize });
  }, [pageSize]);

  return { pagination, setPagination };
};
