/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `none` - None
 * * `hour` - Hour
 * * `day` - Day
 * * `week` - Week
 * * `month` - Month
 * * `quarter` - Quarter
 * * `year` - Year
 * @export
 */
export const AggregationEnum = {
  None: 'none',
  Hour: 'hour',
  Day: 'day',
  Week: 'week',
  Month: 'month',
  Quarter: 'quarter',
  Year: 'year',
} as const;
export type AggregationEnum =
  (typeof AggregationEnum)[keyof typeof AggregationEnum];

export function AggregationEnumFromJSON(json: any): AggregationEnum {
  return AggregationEnumFromJSONTyped(json, false);
}

export function AggregationEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AggregationEnum {
  return json as AggregationEnum;
}

export function AggregationEnumToJSON(value?: AggregationEnum | null): any {
  return value as any;
}
