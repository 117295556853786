import { zodResolver } from '@hookform/resolvers/zod';
import { QueryClient } from '@tanstack/react-query';
import { Row, Table } from '@tanstack/react-table';
import { isEmpty } from 'lodash-es';
import { CircleXIcon } from 'lucide-react';
import { DefaultValues, UseFormReturn, useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { z } from 'zod';

import {
  BillingInstance,
  BillingInstanceBulkCancelWillChargePatientEnum,
  CancellationReasonTypeE44Enum,
  OperationEnum,
} from '@/schema';
import { billingApi } from '@/state/api-instances';

import { FormDialog, FormDialogProps } from '../form-dialog';
import { ResponsiveActionsTrigger } from '../paginated-table-responsive-actions';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../ui/form';
import { Input } from '../ui/input';
import { RadioGroup, RadioGroupItem } from '../ui/radio-group';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select';
import { Tooltip } from '../ui/tooltip';

const CancellationReasonEnum = CancellationReasonTypeE44Enum;
type CancellationReasonEnum = CancellationReasonTypeE44Enum;

const billingCancelSchema = z
  .object({
    cancellationReasonType: z.nativeEnum(CancellationReasonEnum),
    optionalCancellationReasonMessage: z.string().min(3).max(50).optional(),
    willChargePatient: z
      .nativeEnum(BillingInstanceBulkCancelWillChargePatientEnum)
      .optional(),
  })
  .superRefine(
    (
      {
        cancellationReasonType,
        optionalCancellationReasonMessage,
        willChargePatient,
      },
      ctx,
    ) => {
      if (
        cancellationReasonType === CancellationReasonEnum.CashPayPatient &&
        isEmpty(willChargePatient)
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_enum_value,
          received: willChargePatient ?? 'undefined',
          options: Object.values(
            BillingInstanceBulkCancelWillChargePatientEnum,
          ),
          message: 'Please select an option.',
          path: ['willChargePatient'],
        });

        return z.NEVER;
      }

      if (
        cancellationReasonType === CancellationReasonEnum.Other &&
        isEmpty(optionalCancellationReasonMessage)
      ) {
        ctx.addIssue({
          type: 'string',
          code: z.ZodIssueCode.too_small,
          minimum: 3,
          inclusive: true,
          message: 'Please provide a reason for the cancellation.',
          path: ['optionalCancellationReasonMessage'],
        });

        return z.NEVER;
      }
    },
  );

export type BillingCancelType = z.infer<typeof billingCancelSchema>;

export const cancellationReasonLabelEnum = {
  [CancellationReasonEnum.CashPayPatient]: 'Cash-Pay Patient',
  [CancellationReasonEnum.NonReimbursingIns]: 'Non-Reimbursing Insurance',
  [CancellationReasonEnum.DischargedPatient]: 'Patient Discharged',
  [CancellationReasonEnum.Other]: 'Other (Add Description)',
  [CancellationReasonEnum.Blank]: 'Empty (please contact admin)',
} as const;

const cancellationReasonOptions = Object.values(CancellationReasonEnum)
  .filter((val) => val !== CancellationReasonEnum.Blank)
  .map((value) => (
    <SelectItem key={value} value={value}>
      {cancellationReasonLabelEnum[value]}
    </SelectItem>
  ));

const chargePatientLabelEnum = {
  [BillingInstanceBulkCancelWillChargePatientEnum.No]: 'No',
  [BillingInstanceBulkCancelWillChargePatientEnum.Yes]: 'Yes',
  [BillingInstanceBulkCancelWillChargePatientEnum.Maybe]: 'Unsure',
} as const;

const chargePatientOptions = Object.values(
  BillingInstanceBulkCancelWillChargePatientEnum,
).map((value) => (
  <FormItem key={value} className="flex items-center space-x-3 space-y-0">
    <FormControl>
      <RadioGroupItem value={value} />
    </FormControl>

    <FormLabel className="font-normal">
      {chargePatientLabelEnum[value]}
    </FormLabel>
  </FormItem>
));

const cancellationDescriptionReasons: CancellationReasonEnum[] = [
  CancellationReasonEnum.NonReimbursingIns,
  CancellationReasonEnum.Other,
];

interface BillingCancelFieldsetProps {
  form: UseFormReturn<BillingCancelType>;
}

const BillingCancelFieldset = (props: BillingCancelFieldsetProps) => {
  const { form } = props;

  const { cancellationReasonType } = form.watch();

  return (
    <>
      <FormField
        name="cancellationReasonType"
        control={form.control}
        render={({ field }) => (
          <FormItem>
            <FormLabel>Cancellation Reason *</FormLabel>

            <Select defaultValue={field.value} onValueChange={field.onChange}>
              <FormControl>
                <SelectTrigger>
                  <SelectValue placeholder="Please select a duration" />
                </SelectTrigger>
              </FormControl>

              <SelectContent>{cancellationReasonOptions}</SelectContent>
            </Select>

            <FormMessage />
          </FormItem>
        )}
      />

      {cancellationDescriptionReasons.includes(cancellationReasonType) && (
        <FormField
          name="optionalCancellationReasonMessage"
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {cancellationReasonType ===
                CancellationReasonEnum.NonReimbursingIns
                  ? 'Insurance Company'
                  : 'Other Description *'}
              </FormLabel>

              <Input placeholder="Write description" {...field} />

              <FormMessage />
            </FormItem>
          )}
        />
      )}

      <FormField
        name="willChargePatient"
        control={form.control}
        render={({ field }) => (
          <FormItem
            hidden={
              cancellationReasonType !== CancellationReasonEnum.CashPayPatient
            }
          >
            <FormLabel>Charge Patient *</FormLabel>

            <FormControl>
              <RadioGroup
                onValueChange={field.onChange}
                defaultValue={field.value}
                className="flex gap-4"
              >
                {chargePatientOptions}
              </RadioGroup>
            </FormControl>

            <FormMessage />
          </FormItem>
        )}
      />
    </>
  );
};

interface BillingCancelActionProps {
  queryClient: QueryClient;
  row: Row<BillingInstance>;
  table: Table<BillingInstance>;
  onSuccess?: () => void;
}

export const BillingCancelAction = (props: BillingCancelActionProps) => {
  const { queryClient, row, table, onSuccess } = props;

  const defaultValues: DefaultValues<BillingCancelType> = {
    cancellationReasonType: CancellationReasonEnum.CashPayPatient,
    willChargePatient: BillingInstanceBulkCancelWillChargePatientEnum.No,
  };

  const form = useForm<BillingCancelType>({
    resolver: zodResolver(billingCancelSchema),
    defaultValues,
  });

  const cancelBilling: FormDialogProps<BillingCancelType>['submitHandler'] =
    async ({ data, setIsOpen }) => {
      await billingApi.billingBillinginstancesCancelInBulkCreate({
        billingInstanceBulkCancel: {
          uuids: [row.original.uuid],
          operation: OperationEnum.Cancel,
          ...data,
        },
      });

      table.setPageIndex(0);

      toast.success('Successfully cancelled the billing.');

      await queryClient.resetQueries({
        queryKey: ['billingItems'],
      });
      await queryClient.resetQueries({
        queryKey: ['billingNotificationDot'],
      });

      setIsOpen(false);
      onSuccess?.();
    };

  return (
    <Tooltip>
      <FormDialog
        form={form}
        defaultValues={defaultValues}
        dialogTitle="Cancellation Reason"
        dialogDescription="Please enter a reason for cancelling this billing. Canceled billing events can be viewed in the cancelled tab."
        submitLabel="Confirm Cancellation"
        submitProgressLabel="Cancelling..."
        submitHandler={cancelBilling}
        cancelLabel="Do not cancel"
        errorToastLabel="Failed to cancel billing"
        triggerElement={
          <ResponsiveActionsTrigger Icon={CircleXIcon} text="Cancel Billing" />
        }
      >
        <BillingCancelFieldset form={form} />
      </FormDialog>
    </Tooltip>
  );
};
