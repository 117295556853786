/* tslint:disable */

/* eslint-disable */

/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { BillingInstanceBulkCancelCancellationReasonTypeEnum } from './BillingInstanceBulkCancelCancellationReasonTypeEnum';
import {
  BillingInstanceBulkCancelCancellationReasonTypeEnumFromJSON,
  BillingInstanceBulkCancelCancellationReasonTypeEnumFromJSONTyped,
  BillingInstanceBulkCancelCancellationReasonTypeEnumToJSON,
} from './BillingInstanceBulkCancelCancellationReasonTypeEnum';
import type { BillingInstanceBulkCancelWillChargePatientEnum } from './BillingInstanceBulkCancelWillChargePatientEnum';
import {
  BillingInstanceBulkCancelWillChargePatientEnumFromJSON,
  BillingInstanceBulkCancelWillChargePatientEnumFromJSONTyped,
  BillingInstanceBulkCancelWillChargePatientEnumToJSON,
} from './BillingInstanceBulkCancelWillChargePatientEnum';
import type { OperationEnum } from './OperationEnum';
import {
  OperationEnumFromJSON,
  OperationEnumFromJSONTyped,
  OperationEnumToJSON,
} from './OperationEnum';

/**
 * Serializer for bulk cancellations of BillingInstances.
 *
 * This serializer is used to cancel or uncancel multiple BillingInstances
 * at once. It validates the input data and performs the cancellation operation
 * on the BillingInstances.
 * @export
 * @interface BillingInstanceBulkCancel
 */
export interface BillingInstanceBulkCancel {
  /**
   * List of BillingInstance UUIDs to be updated.
   * @type {Array<string>}
   * @memberof BillingInstanceBulkCancel
   */
  uuids: Array<string>;
  /**
   * Operation to be performed.
   *
   * * `CANCEL` - CANCEL
   * * `UNCANCEL` - UNCANCEL
   * * `RECONCILE` - RECONCILE
   * * `UNRECONCILE` - UNRECONCILE
   * @type {OperationEnum}
   * @memberof BillingInstanceBulkCancel
   */
  operation: OperationEnum;
  /**
   * Reason for cancellation.
   *
   * * `CASH_PAY_PATIENT` - CASH_PAY_PATIENT
   * * `NON_REIMBURSING_INS` - NON_REIMBURSING_INS
   * * `DISCHARGED_PATIENT` - DISCHARGED_PATIENT
   * * `OTHER` - OTHER
   * * `BLANK` - BLANK
   * @type {BillingInstanceBulkCancelCancellationReasonTypeEnum}
   * @memberof BillingInstanceBulkCancel
   */
  cancellationReasonType?: BillingInstanceBulkCancelCancellationReasonTypeEnum;
  /**
   * Optional message for the cancellation reason.
   * @type {string}
   * @memberof BillingInstanceBulkCancel
   */
  optionalCancellationReasonMessage?: string;
  /**
   * Whether to charge Sara for the cancellation.
   *
   * * `YES` - YES
   * * `NO` - NO
   * * `MAYBE` - MAYBE
   * @type {BillingInstanceBulkCancelWillChargePatientEnum}
   * @memberof BillingInstanceBulkCancel
   */
  willChargePatient?: BillingInstanceBulkCancelWillChargePatientEnum;
}

/**
 * Check if a given object implements the BillingInstanceBulkCancel interface.
 */
export function instanceOfBillingInstanceBulkCancel(value: object): boolean {
  if (!('uuids' in value)) return false;
  if (!('operation' in value)) return false;
  return true;
}

export function BillingInstanceBulkCancelFromJSON(
  json: any,
): BillingInstanceBulkCancel {
  return BillingInstanceBulkCancelFromJSONTyped(json, false);
}

export function BillingInstanceBulkCancelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BillingInstanceBulkCancel {
  if (json == null) {
    return json;
  }
  return {
    uuids: json['uuids'],
    operation: OperationEnumFromJSON(json['operation']),
    cancellationReasonType:
      json['cancellation_reason_type'] == null
        ? undefined
        : BillingInstanceBulkCancelCancellationReasonTypeEnumFromJSON(
            json['cancellation_reason_type'],
          ),
    optionalCancellationReasonMessage:
      json['optional_cancellation_reason_message'] == null
        ? undefined
        : json['optional_cancellation_reason_message'],
    willChargePatient:
      json['will_charge_patient'] == null
        ? undefined
        : BillingInstanceBulkCancelWillChargePatientEnumFromJSON(
            json['will_charge_patient'],
          ),
  };
}

export function BillingInstanceBulkCancelToJSON(
  value?: BillingInstanceBulkCancel | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    uuids: value['uuids'],
    operation: OperationEnumToJSON(value['operation']),
    cancellation_reason_type:
      BillingInstanceBulkCancelCancellationReasonTypeEnumToJSON(
        value['cancellationReasonType'],
      ),
    optional_cancellation_reason_message:
      value['optionalCancellationReasonMessage'],
    will_charge_patient: BillingInstanceBulkCancelWillChargePatientEnumToJSON(
      value['willChargePatient'],
    ),
  };
}
