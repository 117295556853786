/* tslint:disable */

/* eslint-disable */

/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type { PatchedUser, User } from '../models/index';
import {
  PatchedUserFromJSON,
  PatchedUserToJSON,
  UserFromJSON,
  UserToJSON,
} from '../models/index';
import * as runtime from '../runtime';

export interface AccountUpdateUserDataPartialUpdateRequest {
  userId: number;
  patchedUser?: PatchedUser;
}

export interface AccountUpdateUserDataUpdateRequest {
  userId: number;
  user: User;
}

/**
 *
 */
export class AccountApi extends runtime.BaseAPI {
  /**
   */
  async accountUniqueCheckCreateRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/account/unique_check`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async accountUniqueCheckCreate(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.accountUniqueCheckCreateRaw(initOverrides);
  }

  /**
   * For a provider to be able to update a patient\'s or one\'s own metadata.
   */
  async accountUpdateUserDataPartialUpdateRaw(
    requestParameters: AccountUpdateUserDataPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<User>> {
    if (requestParameters['userId'] == null) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter "userId" was null or undefined when calling accountUpdateUserDataPartialUpdate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/account/update-user-data/{user_id}`.replace(
          `{${'user_id'}}`,
          encodeURIComponent(String(requestParameters['userId'])),
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: PatchedUserToJSON(requestParameters['patchedUser']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserFromJSON(jsonValue),
    );
  }

  /**
   * For a provider to be able to update a patient\'s or one\'s own metadata.
   */
  async accountUpdateUserDataPartialUpdate(
    requestParameters: AccountUpdateUserDataPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<User> {
    const response = await this.accountUpdateUserDataPartialUpdateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * For a provider to be able to update a patient\'s or one\'s own metadata.
   */
  async accountUpdateUserDataUpdateRaw(
    requestParameters: AccountUpdateUserDataUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<User>> {
    if (requestParameters['userId'] == null) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter "userId" was null or undefined when calling accountUpdateUserDataUpdate().',
      );
    }

    if (requestParameters['user'] == null) {
      throw new runtime.RequiredError(
        'user',
        'Required parameter "user" was null or undefined when calling accountUpdateUserDataUpdate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/account/update-user-data/{user_id}`.replace(
          `{${'user_id'}}`,
          encodeURIComponent(String(requestParameters['userId'])),
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UserToJSON(requestParameters['user']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserFromJSON(jsonValue),
    );
  }

  /**
   * For a provider to be able to update a patient\'s or one\'s own metadata.
   */
  async accountUpdateUserDataUpdate(
    requestParameters: AccountUpdateUserDataUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<User> {
    const response = await this.accountUpdateUserDataUpdateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}
