import { BillingInstance, TypeAc3Enum } from '@/schema';
import { cn } from '@/utils/tailwind';

import { Badge } from '../ui/badge';
import { Tooltip, TooltipContent, TooltipTrigger } from '../ui/tooltip';

interface BillingCodeCellProps {
  codeType: TypeAc3Enum;
  noTooltip?: boolean;
  strikeThrough?: boolean;
  is81?: boolean;
  unitsOf98981?: BillingInstance['unitsOf98981'];
}

const billingInstanceCodeEnum = {
  [TypeAc3Enum.Connect]: {
    supportText: '🤝 Connect (98975)',
    tooltip: 'Patient monitored for 16 or more days.',
  },
  [TypeAc3Enum.Engage]: {
    supportText: '💬 Engage (98977)',
    tooltip: 'Patient engaged for 16 or more days.',
  },
  [TypeAc3Enum.Support]: (is81?: boolean) => ({
    supportText: `💪 Support (9898${is81 ? '1' : '0'})`,
    tooltip: `RTM data reviewed & discussed (${is81 ? 'additional' : 'first'} 20 minutes)`,
  }),
} as const;

const BillingCodeItem = ({
  codeType,
  is81,
  strikeThrough,
}: BillingCodeCellProps) => {
  const { supportText, tooltip } =
    codeType === TypeAc3Enum.Support
      ? billingInstanceCodeEnum[codeType](is81)
      : billingInstanceCodeEnum[codeType];

  return (
    <Tooltip>
      <TooltipTrigger>
        <Badge
          variant={strikeThrough ? 'outline' : 'secondary'}
          className={cn('rounded-md', strikeThrough && 'line-through')}
        >
          {supportText}
        </Badge>
      </TooltipTrigger>

      <TooltipContent>{tooltip}</TooltipContent>
    </Tooltip>
  );
};

export const BillingCodeCell = ({
  codeType,
  noTooltip,
  strikeThrough,
  unitsOf98981,
}: BillingCodeCellProps) => {
  return (
    <div
      className={cn(
        'flex flex-col items-start gap-1',
        noTooltip && 'pointer-events-none',
      )}
    >
      <BillingCodeItem codeType={codeType} strikeThrough={strikeThrough} />

      {codeType === TypeAc3Enum.Support && unitsOf98981
        ? unitsOf98981 > 0 && (
            <div className="flex gap-2">
              <BillingCodeItem
                codeType={codeType}
                is81
                strikeThrough={strikeThrough}
              />
              <span className="text-muted-foreground/70">x {unitsOf98981}</span>
            </div>
          )
        : null}
    </div>
  );
};
