/* tslint:disable */

/* eslint-disable */

/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { ActivityTypesEnum } from './ActivityTypesEnum';
import {
  ActivityTypesEnumFromJSON,
  ActivityTypesEnumFromJSONTyped,
  ActivityTypesEnumToJSON,
} from './ActivityTypesEnum';

/**
 * Only used for schema generation.
 * @export
 * @interface PatchedInteractionLogSerializerRequest
 */
export interface PatchedInteractionLogSerializerRequest {
  /**
   *
   * @type {number}
   * @memberof PatchedInteractionLogSerializerRequest
   */
  readonly id?: number;
  /**
   *
   * @type {number}
   * @memberof PatchedInteractionLogSerializerRequest
   */
  patient?: number;
  /**
   *
   * @type {number}
   * @memberof PatchedInteractionLogSerializerRequest
   */
  provider?: number;
  /**
   *
   * @type {number}
   * @memberof PatchedInteractionLogSerializerRequest
   */
  durationSecs?: number;
  /**
   *
   * @type {Array<ActivityTypesEnum>}
   * @memberof PatchedInteractionLogSerializerRequest
   */
  activityTypes?: Array<ActivityTypesEnum>;
  /**
   *
   * @type {string}
   * @memberof PatchedInteractionLogSerializerRequest
   */
  noteObservedTrends?: string;
  /**
   *
   * @type {string}
   * @memberof PatchedInteractionLogSerializerRequest
   */
  notePlanModification?: string;
  /**
   *
   * @type {Date}
   * @memberof PatchedInteractionLogSerializerRequest
   */
  interactedDatetime?: Date;
  /**
   *
   * @type {boolean}
   * @memberof PatchedInteractionLogSerializerRequest
   */
  autoGenerated?: boolean;
  /**
   *
   * @type {number}
   * @memberof PatchedInteractionLogSerializerRequest
   */
  billinginstance?: number;
}

/**
 * Check if a given object implements the PatchedInteractionLogSerializerRequest interface.
 */
export function instanceOfPatchedInteractionLogSerializerRequest(
  value: object,
): boolean {
  return true;
}

export function PatchedInteractionLogSerializerRequestFromJSON(
  json: any,
): PatchedInteractionLogSerializerRequest {
  return PatchedInteractionLogSerializerRequestFromJSONTyped(json, false);
}

export function PatchedInteractionLogSerializerRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PatchedInteractionLogSerializerRequest {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'] == null ? undefined : json['id'],
    patient: json['patient'] == null ? undefined : json['patient'],
    provider: json['provider'] == null ? undefined : json['provider'],
    durationSecs:
      json['duration_secs'] == null ? undefined : json['duration_secs'],
    activityTypes:
      json['activity_types'] == null
        ? undefined
        : (json['activity_types'] as Array<any>).map(ActivityTypesEnumFromJSON),
    noteObservedTrends:
      json['note_observed_trends'] == null
        ? undefined
        : json['note_observed_trends'],
    notePlanModification:
      json['note_plan_modification'] == null
        ? undefined
        : json['note_plan_modification'],
    interactedDatetime:
      json['interacted_datetime'] == null
        ? undefined
        : new Date(json['interacted_datetime']),
    autoGenerated:
      json['auto_generated'] == null ? undefined : json['auto_generated'],
    billinginstance:
      json['billinginstance'] == null ? undefined : json['billinginstance'],
  };
}

export function PatchedInteractionLogSerializerRequestToJSON(
  value?: PatchedInteractionLogSerializerRequest | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    patient: value['patient'],
    provider: value['provider'],
    duration_secs: value['durationSecs'],
    activity_types:
      value['activityTypes'] == null
        ? undefined
        : (value['activityTypes'] as Array<any>).map(ActivityTypesEnumToJSON),
    note_observed_trends: value['noteObservedTrends'],
    note_plan_modification: value['notePlanModification'],
    interacted_datetime:
      value['interactedDatetime'] == null
        ? undefined
        : value['interactedDatetime'].toISOString(),
    auto_generated: value['autoGenerated'],
    billinginstance: value['billinginstance'],
  };
}
