/* tslint:disable */

/* eslint-disable */

/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { ClinicMembership } from './ClinicMembership';
import {
  ClinicMembershipFromJSON,
  ClinicMembershipFromJSONTyped,
  ClinicMembershipToJSON,
} from './ClinicMembership';

/**
 *
 * @export
 * @interface UserSerializerSlim
 */
export interface UserSerializerSlim {
  /**
   *
   * @type {number}
   * @memberof UserSerializerSlim
   */
  readonly id: number;
  /**
   *
   * @type {string}
   * @memberof UserSerializerSlim
   */
  readonly fullName: string;
  /**
   *
   * @type {string}
   * @memberof UserSerializerSlim
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof UserSerializerSlim
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof UserSerializerSlim
   */
  email?: string;
  /**
   * Designates whether this user should be treated as active. Unselect this instead of deleting accounts.
   * @type {boolean}
   * @memberof UserSerializerSlim
   */
  isActive?: boolean;
  /**
   *
   * @type {string}
   * @memberof UserSerializerSlim
   */
  readonly defaultClinic: string;
  /**
   *
   * @type {Array<ClinicMembership>}
   * @memberof UserSerializerSlim
   */
  readonly clinicMemberships: Array<ClinicMembership>;
}

/**
 * Check if a given object implements the UserSerializerSlim interface.
 */
export function instanceOfUserSerializerSlim(value: object): boolean {
  if (!('id' in value)) return false;
  if (!('fullName' in value)) return false;
  if (!('defaultClinic' in value)) return false;
  if (!('clinicMemberships' in value)) return false;
  return true;
}

export function UserSerializerSlimFromJSON(json: any): UserSerializerSlim {
  return UserSerializerSlimFromJSONTyped(json, false);
}

export function UserSerializerSlimFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserSerializerSlim {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'],
    fullName: json['full_name'],
    firstName: json['first_name'] == null ? undefined : json['first_name'],
    lastName: json['last_name'] == null ? undefined : json['last_name'],
    email: json['email'] == null ? undefined : json['email'],
    isActive: json['is_active'] == null ? undefined : json['is_active'],
    defaultClinic: json['default_clinic'],
    clinicMemberships: (json['clinic_memberships'] as Array<any>).map(
      ClinicMembershipFromJSON,
    ),
  };
}

export function UserSerializerSlimToJSON(
  value?: UserSerializerSlim | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    first_name: value['firstName'],
    last_name: value['lastName'],
    email: value['email'],
    is_active: value['isActive'],
  };
}
