// import logger from "redux-logger";
import { applyMiddleware, compose, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// defaults to localStorage for web
import thunk from 'redux-thunk';
import 'regenerator-runtime/runtime';

import combineReducers from './reducers/index';

const initialState = {};
// alter implementation found here: https://github.com/captbaritone/raven-for-redux/issues/93
// const sentry = store => {
//   Sentry.configureScope(scope => {
//     scope.addEventProcessor(event => {
//       // line below assumes that username is stores under user in the state
//       const {
//         user: { username },
//         ...state
//       } = store.getState();

//       return {
//         ...event,
//         extra: {
//           ...event.extra,
//           "redux:state": state
//         },
//         user: {
//           ...event.user,
//           username
//         }
//       };
//     });
//   });

//   return next => action => {
//     Sentry.addBreadcrumb({
//       category: "redux-action",
//       message: action.type
//     });

//     return next(action);
//   };
// };

export const middlewares = [
  thunk,
  // logger
];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_STATE') {
    storage.removeItem('persist:root:dialog');
    storage.removeItem('persist:root:theme');
    storage.removeItem('persist:root:Session');
    storage.removeItem('persist:root:entities');
    storage.removeItem('persist:root:PatientAssignments');
    state = undefined;
  }
  return combineReducers(state, action);
};

const persistConfig = {
  key: 'root',
  storage,
  // Whitelist (Save Specific Reducers)
  whitelist: [
    'Session',
    'Users',
    'ClinicDetails',
    'dialog',
    'PatientAssignments',
    'theme', // comment when debugging
    'entities',
  ],
  // blacklist: ['theme'] // uncomment when debugging
  // Blacklist (Don't Save Specific Reducers)
  // blacklist: ["counterReducer"]
};

export const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = createStore(
  persistedReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middlewares)),
);
export const persistor = persistStore(store);
