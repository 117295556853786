import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { loggedUserIdSelector } from '@/old/state/reducers/login_reducer';
import { providerMetadataApi } from '@/state/api-instances';
import { useMetadataState } from '@/state/metadata-state';

export const useMetadataSync = () => {
  const loggedUserId: number | null = useSelector(loggedUserIdSelector);

  const { setMetadata } = useMetadataState();

  const { data } = useQuery({
    queryKey: ['metadata'],
    queryFn: () =>
      providerMetadataApi.ptProvidersRetrieve({ id: `${loggedUserId}` }),
    enabled: loggedUserId !== null,
  });

  useEffect(() => {
    if (data) {
      setMetadata(data);
    } else {
      setMetadata();
    }
  }, [data, setMetadata]);
};
