import { ElementRef, forwardRef } from 'react';

import { cn } from '@/utils/tailwind';

import { Badge } from './ui/badge';
import { Tooltip, TooltipContent, TooltipTrigger } from './ui/tooltip';

interface BadgeTooltipProps {
  label: React.ReactNode;
  tooltip: React.ReactNode;
}

export const BadgeTooltip = (props: BadgeTooltipProps) => {
  const { label, tooltip } = props;

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <div>
          <Badge variant="outline">{label}</Badge>
        </div>
      </TooltipTrigger>

      <TooltipContent>{tooltip}</TooltipContent>
    </Tooltip>
  );
};

interface NotificationDotProps {
  isCorner?: boolean;
}

export const NotificationDot = forwardRef<
  ElementRef<'div'>,
  NotificationDotProps
>(({ ...props }: NotificationDotProps, ref) => {
  const { isCorner } = props;

  return (
    <div
      ref={ref}
      data-testid="notification-dot"
      className={cn(
        'size-3 rounded-full bg-destructive',
        isCorner && 'absolute right-0.5 top-0.5',
      )}
    />
  );
});
NotificationDot.displayName = 'NotificationDot';

interface NotificationDotTooltipProps
  extends React.PropsWithChildren,
    NotificationDotProps {}

export const NotificationDotTooltip = (props: NotificationDotTooltipProps) => {
  const { children, isCorner } = props;

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <NotificationDot isCorner={isCorner} />
      </TooltipTrigger>

      <TooltipContent>{children}</TooltipContent>
    </Tooltip>
  );
};
