/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `THERAPIST` - Therapist
 * * `ASSISTANT` - Assistant
 * @export
 */
export const Type956Enum = {
  Therapist: 'THERAPIST',
  Assistant: 'ASSISTANT',
} as const;
export type Type956Enum = (typeof Type956Enum)[keyof typeof Type956Enum];

export function Type956EnumFromJSON(json: any): Type956Enum {
  return Type956EnumFromJSONTyped(json, false);
}

export function Type956EnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Type956Enum {
  return json as Type956Enum;
}

export function Type956EnumToJSON(value?: Type956Enum | null): any {
  return value as any;
}
