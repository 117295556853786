import { store } from '@/old/state/store';

export const intercomWrapper = (method) => {
  window?.Intercom(method);
};
export const hideIntercom = () => {
  window.intercomSettings = {
    app_id: import.meta.env.VITE_INTERCOM_APP_ID,
    hide_default_launcher: true,
  };
  window?.Intercom?.('update', window?.intercomSettings);
};

const setLauncherSettings = (hideLauncher = false) => {
  window.intercomSettings = {
    app_id: import.meta.env.VITE_INTERCOM_APP_ID,
    action_color: '#d8706b',
    background_color: '#0493c3',
    hide_default_launcher: hideLauncher,
    alignment: 'left',
  };
};
function getPrereqData() {
  return {
    app_id: import.meta.env.VITE_INTERCOM_APP_ID,
    user_id: store.getState().Session && store.getState().Session.user.id,
    user_hash:
      store.getState().Session &&
      store.getState().Session.api.intercom_user_hash,
    email: store.getState().Session && store.getState().Session.user.email,
  };
}
export const bootIntercom = () => {
  const intercomSettings = getPrereqData();
  if (window?.Intercom) window?.Intercom('boot', intercomSettings);
  setLauncherSettings(false);
};

const currentTime = parseInt(new Date().getTime() / 1000);

export const updateIntercom = () => {
  window?.Intercom?.('update', {
    last_request_at: currentTime,
  });
  setLauncherSettings(false);
};

export const shutdownIntercom = () => {
  window?.Intercom?.('shutdown', {
    last_request_at: currentTime,
  });
};

export function identifyIntercom(user) {
  const getIntercomAccountTypeLabel = (userType) => {
    if (userType === 'pt') return 'Physical Therapist';
    if (userType === 'patient') return 'Patient';
    return 'undef';
  };
  const identificationVerification = getPrereqData();
  const basicUserInfo = {
    username: user.username,
    account_type: getIntercomAccountTypeLabel(user.user_type),
    name: `${user.first_name} ${user.last_name}`,
    phone: user.phone,
    created: user.created,
  };

  const finalPayload = {
    ...identificationVerification,
    ...basicUserInfo,
  };
  window?.Intercom?.('update', finalPayload);
}
