import { useEffect } from 'react';

import {
  ThemesEnum,
  handleSystemTheme,
  useThemeState,
} from '@/state/theme-state';

// This hook will sync the theme with the body class, we use it in the app or main components
export const useThemeSync = () => {
  const { theme } = useThemeState();

  useEffect(() => {
    document.body.classList.remove(...Object.values(ThemesEnum));

    const systemTheme = handleSystemTheme(theme);

    document.body.classList.add(systemTheme);
  }, [theme]);
};
