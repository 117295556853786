import { Button } from './ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from './ui/dialog';

interface ConfirmationDialogProps extends React.PropsWithChildren {
  title?: React.ReactNode;
  description: React.ReactNode;
  hideDescription?: true;
  message?: React.ReactNode;
  onConfirm?: () => void | Promise<void>;
  onCancel?: () => void | Promise<void>;
}

export const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  const {
    title = 'Are you sure?',
    description,
    hideDescription,
    message,
    children,
    onConfirm,
    onCancel,
  } = props;
  return (
    <Dialog>
      <DialogTrigger asChild>{children}</DialogTrigger>

      <DialogContent>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription hidden={hideDescription}>
            {description}
          </DialogDescription>
        </DialogHeader>

        {message}

        <DialogFooter>
          <DialogClose asChild>
            <Button onClick={onConfirm}>Confirm</Button>
          </DialogClose>

          <DialogClose asChild>
            <Button variant="ghost" onClick={onCancel}>
              Cancel
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
