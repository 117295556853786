/* tslint:disable */

/* eslint-disable */

/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { AccountOriginEnum } from './AccountOriginEnum';
import {
  AccountOriginEnumFromJSON,
  AccountOriginEnumFromJSONTyped,
  AccountOriginEnumToJSON,
} from './AccountOriginEnum';
import type { ClinicMembership } from './ClinicMembership';
import {
  ClinicMembershipFromJSON,
  ClinicMembershipFromJSONTyped,
  ClinicMembershipToJSON,
} from './ClinicMembership';
import type { TimezoneEnum } from './TimezoneEnum';
import {
  TimezoneEnumFromJSON,
  TimezoneEnumFromJSONTyped,
  TimezoneEnumToJSON,
} from './TimezoneEnum';
import type { UserTypeEnum } from './UserTypeEnum';
import {
  UserTypeEnumFromJSON,
  UserTypeEnumFromJSONTyped,
  UserTypeEnumToJSON,
} from './UserTypeEnum';

/**
 *
 * @export
 * @interface PatchedUser
 */
export interface PatchedUser {
  /**
   *
   * @type {number}
   * @memberof PatchedUser
   */
  readonly id?: number;
  /**
   *
   * @type {string}
   * @memberof PatchedUser
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof PatchedUser
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof PatchedUser
   */
  readonly fullName?: string;
  /**
   *
   * @type {string}
   * @memberof PatchedUser
   */
  username?: string;
  /**
   *
   * @type {string}
   * @memberof PatchedUser
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof PatchedUser
   */
  phone?: string;
  /**
   *
   * @type {AccountOriginEnum}
   * @memberof PatchedUser
   */
  accountOrigin?: AccountOriginEnum;
  /**
   *
   * @type {number}
   * @memberof PatchedUser
   */
  createdBy?: number;
  /**
   *
   * @type {string}
   * @memberof PatchedUser
   */
  password?: string;
  /**
   *
   * @type {string}
   * @memberof PatchedUser
   */
  defaultClinic?: string;
  /**
   *
   * @type {boolean}
   * @memberof PatchedUser
   */
  isDummy?: boolean;
  /**
   *
   * @type {string}
   * @memberof PatchedUser
   */
  language?: string;
  /**
   *
   * @type {UserTypeEnum}
   * @memberof PatchedUser
   */
  userType?: UserTypeEnum;
  /**
   *
   * @type {number}
   * @memberof PatchedUser
   */
  ptUsername?: number;
  /**
   *
   * @type {Date}
   * @memberof PatchedUser
   */
  smsOptOutDate?: Date;
  /**
   *
   * @type {Date}
   * @memberof PatchedUser
   */
  smsUnreachableDate?: Date;
  /**
   *
   * @type {Array<ClinicMembership>}
   * @memberof PatchedUser
   */
  readonly clinicMemberships?: Array<ClinicMembership>;
  /**
   *
   * @type {TimezoneEnum}
   * @memberof PatchedUser
   */
  timezone?: TimezoneEnum;
  /**
   * The email of the primary provider.
   * @type {string}
   * @memberof PatchedUser
   */
  primaryProvider?: string;
  /**
   *
   * @type {Date}
   * @memberof PatchedUser
   */
  birthDate?: Date;
}

/**
 * Check if a given object implements the PatchedUser interface.
 */
export function instanceOfPatchedUser(value: object): boolean {
  return true;
}

export function PatchedUserFromJSON(json: any): PatchedUser {
  return PatchedUserFromJSONTyped(json, false);
}

export function PatchedUserFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PatchedUser {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'] == null ? undefined : json['id'],
    firstName: json['first_name'] == null ? undefined : json['first_name'],
    lastName: json['last_name'] == null ? undefined : json['last_name'],
    fullName: json['full_name'] == null ? undefined : json['full_name'],
    username: json['username'] == null ? undefined : json['username'],
    email: json['email'] == null ? undefined : json['email'],
    phone: json['phone'] == null ? undefined : json['phone'],
    accountOrigin:
      json['account_origin'] == null
        ? undefined
        : AccountOriginEnumFromJSON(json['account_origin']),
    createdBy: json['created_by'] == null ? undefined : json['created_by'],
    password: json['password'] == null ? undefined : json['password'],
    defaultClinic:
      json['default_clinic'] == null ? undefined : json['default_clinic'],
    isDummy: json['is_dummy'] == null ? undefined : json['is_dummy'],
    language: json['language'] == null ? undefined : json['language'],
    userType:
      json['user_type'] == null
        ? undefined
        : UserTypeEnumFromJSON(json['user_type']),
    ptUsername: json['pt_username'] == null ? undefined : json['pt_username'],
    smsOptOutDate:
      json['sms_opt_out_date'] == null
        ? undefined
        : new Date(json['sms_opt_out_date']),
    smsUnreachableDate:
      json['sms_unreachable_date'] == null
        ? undefined
        : new Date(json['sms_unreachable_date']),
    clinicMemberships:
      json['clinic_memberships'] == null
        ? undefined
        : (json['clinic_memberships'] as Array<any>).map(
            ClinicMembershipFromJSON,
          ),
    timezone:
      json['timezone'] == null
        ? undefined
        : TimezoneEnumFromJSON(json['timezone']),
    primaryProvider:
      json['primary_provider'] == null ? undefined : json['primary_provider'],
    birthDate:
      json['birth_date'] == null ? undefined : new Date(json['birth_date']),
  };
}

export function PatchedUserToJSON(value?: PatchedUser | null): any {
  if (value == null) {
    return value;
  }
  return {
    first_name: value['firstName'],
    last_name: value['lastName'],
    username: value['username'],
    email: value['email'],
    phone: value['phone'],
    account_origin: AccountOriginEnumToJSON(value['accountOrigin']),
    created_by: value['createdBy'],
    password: value['password'],
    default_clinic: value['defaultClinic'],
    is_dummy: value['isDummy'],
    language: value['language'],
    user_type: UserTypeEnumToJSON(value['userType']),
    pt_username: value['ptUsername'],
    sms_opt_out_date:
      value['smsOptOutDate'] == null
        ? undefined
        : (value['smsOptOutDate'] as any).toISOString(),
    sms_unreachable_date:
      value['smsUnreachableDate'] == null
        ? undefined
        : (value['smsUnreachableDate'] as any).toISOString(),
    timezone: TimezoneEnumToJSON(value['timezone']),
    primary_provider: value['primaryProvider'],
    birth_date:
      value['birthDate'] == null
        ? undefined
        : (value['birthDate'] as any).toISOString().substring(0, 10),
  };
}
