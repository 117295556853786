/* tslint:disable */

/* eslint-disable */

/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { Summary } from './Summary';
import {
  SummaryFromJSON,
  SummaryFromJSONTyped,
  SummaryToJSON,
} from './Summary';

/**
 *
 * @export
 * @interface ChatChannel
 */
export interface ChatChannel {
  /**
   *
   * @type {Summary}
   * @memberof ChatChannel
   */
  readonly lastSummary: Summary;
  /**
   *
   * @type {string}
   * @memberof ChatChannel
   */
  channelUrl?: string;
}

/**
 * Check if a given object implements the ChatChannel interface.
 */
export function instanceOfChatChannel(value: object): boolean {
  if (!('lastSummary' in value)) return false;
  return true;
}

export function ChatChannelFromJSON(json: any): ChatChannel {
  return ChatChannelFromJSONTyped(json, false);
}

export function ChatChannelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ChatChannel {
  if (json == null) {
    return json;
  }
  return {
    lastSummary: SummaryFromJSON(json['last_summary']),
    channelUrl: json['channel_url'] == null ? undefined : json['channel_url'],
  };
}

export function ChatChannelToJSON(value?: ChatChannel | null): any {
  if (value == null) {
    return value;
  }
  return {
    channel_url: value['channelUrl'],
  };
}
