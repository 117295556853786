/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `YES` - Yes
 * * `NO` - No
 * * `MAYBE` - Maybe
 * @export
 */
export const WillChargePatientE4bEnum = {
  Yes: 'YES',
  No: 'NO',
  Maybe: 'MAYBE',
} as const;
export type WillChargePatientE4bEnum =
  (typeof WillChargePatientE4bEnum)[keyof typeof WillChargePatientE4bEnum];

export function WillChargePatientE4bEnumFromJSON(
  json: any,
): WillChargePatientE4bEnum {
  return WillChargePatientE4bEnumFromJSONTyped(json, false);
}

export function WillChargePatientE4bEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): WillChargePatientE4bEnum {
  return json as WillChargePatientE4bEnum;
}

export function WillChargePatientE4bEnumToJSON(
  value?: WillChargePatientE4bEnum | null,
): any {
  return value as any;
}
