/* tslint:disable */

/* eslint-disable */

/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { Type956Enum } from './Type956Enum';
import {
  Type956EnumFromJSON,
  Type956EnumFromJSONTyped,
  Type956EnumToJSON,
} from './Type956Enum';
import type { UserSerializerSlim } from './UserSerializerSlim';
import {
  UserSerializerSlimFromJSON,
  UserSerializerSlimFromJSONTyped,
  UserSerializerSlimToJSON,
} from './UserSerializerSlim';

/**
 *
 * @export
 * @interface ProviderSerializerSlim
 */
export interface ProviderSerializerSlim {
  /**
   *
   * @type {UserSerializerSlim}
   * @memberof ProviderSerializerSlim
   */
  readonly user: UserSerializerSlim;
  /**
   *
   * @type {Type956Enum}
   * @memberof ProviderSerializerSlim
   */
  type?: Type956Enum;
}

/**
 * Check if a given object implements the ProviderSerializerSlim interface.
 */
export function instanceOfProviderSerializerSlim(value: object): boolean {
  if (!('user' in value)) return false;
  return true;
}

export function ProviderSerializerSlimFromJSON(
  json: any,
): ProviderSerializerSlim {
  return ProviderSerializerSlimFromJSONTyped(json, false);
}

export function ProviderSerializerSlimFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ProviderSerializerSlim {
  if (json == null) {
    return json;
  }
  return {
    user: UserSerializerSlimFromJSON(json['user']),
    type: json['type'] == null ? undefined : Type956EnumFromJSON(json['type']),
  };
}

export function ProviderSerializerSlimToJSON(
  value?: ProviderSerializerSlim | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    type: Type956EnumToJSON(value['type']),
  };
}
