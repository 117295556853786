/* tslint:disable */

/* eslint-disable */

/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  Feedback,
  PatchedSummary,
  Summary,
  TractorscopeUrlReq,
  TractorscopeUrlResponse,
} from '../models/index';
import {
  FeedbackFromJSON,
  FeedbackToJSON,
  PatchedSummaryFromJSON,
  PatchedSummaryToJSON,
  SummaryFromJSON,
  SummaryToJSON,
  TractorscopeUrlReqFromJSON,
  TractorscopeUrlReqToJSON,
  TractorscopeUrlResponseFromJSON,
  TractorscopeUrlResponseToJSON,
} from '../models/index';
import * as runtime from '../runtime';

export interface VendorsSummaryAddFeedbackCreateRequest {
  id: number;
  feedback?: Feedback;
}

export interface VendorsSummaryCreateRequest {
  summary: Summary;
}

export interface VendorsSummaryDestroyRequest {
  id: number;
}

export interface VendorsSummaryPartialUpdateRequest {
  id: number;
  patchedSummary?: PatchedSummary;
}

export interface VendorsSummaryRetrieveRequest {
  id: number;
}

export interface VendorsSummaryUpdateRequest {
  id: number;
  summary: Summary;
}

export interface VendorsWebhooksGenerateTractorscopeUrlsCreateRequest {
  tractorscopeUrlReq: TractorscopeUrlReq;
}

/**
 *
 */
export class VendorsApi extends runtime.BaseAPI {
  /**
   * Allows users to add feedback to a specific summary. Requires authentication.
   * Add Feedback to a Summary
   */
  async vendorsSummaryAddFeedbackCreateRaw(
    requestParameters: VendorsSummaryAddFeedbackCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Summary>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling vendorsSummaryAddFeedbackCreate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/vendors/summary/{id}/add_feedback/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters['id'])),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: FeedbackToJSON(requestParameters['feedback']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SummaryFromJSON(jsonValue),
    );
  }

  /**
   * Allows users to add feedback to a specific summary. Requires authentication.
   * Add Feedback to a Summary
   */
  async vendorsSummaryAddFeedbackCreate(
    requestParameters: VendorsSummaryAddFeedbackCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Summary> {
    const response = await this.vendorsSummaryAddFeedbackCreateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async vendorsSummaryCreateRaw(
    requestParameters: VendorsSummaryCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Summary>> {
    if (requestParameters['summary'] == null) {
      throw new runtime.RequiredError(
        'summary',
        'Required parameter "summary" was null or undefined when calling vendorsSummaryCreate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/vendors/summary/`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: SummaryToJSON(requestParameters['summary']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SummaryFromJSON(jsonValue),
    );
  }

  /**
   */
  async vendorsSummaryCreate(
    requestParameters: VendorsSummaryCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Summary> {
    const response = await this.vendorsSummaryCreateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async vendorsSummaryDestroyRaw(
    requestParameters: VendorsSummaryDestroyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling vendorsSummaryDestroy().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/vendors/summary/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters['id'])),
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async vendorsSummaryDestroy(
    requestParameters: VendorsSummaryDestroyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.vendorsSummaryDestroyRaw(requestParameters, initOverrides);
  }

  /**
   */
  async vendorsSummaryListRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<Summary>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/vendors/summary/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(SummaryFromJSON),
    );
  }

  /**
   */
  async vendorsSummaryList(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<Summary>> {
    const response = await this.vendorsSummaryListRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async vendorsSummaryPartialUpdateRaw(
    requestParameters: VendorsSummaryPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Summary>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling vendorsSummaryPartialUpdate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/vendors/summary/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters['id'])),
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: PatchedSummaryToJSON(requestParameters['patchedSummary']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SummaryFromJSON(jsonValue),
    );
  }

  /**
   */
  async vendorsSummaryPartialUpdate(
    requestParameters: VendorsSummaryPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Summary> {
    const response = await this.vendorsSummaryPartialUpdateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async vendorsSummaryRetrieveRaw(
    requestParameters: VendorsSummaryRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Summary>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling vendorsSummaryRetrieve().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/vendors/summary/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters['id'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SummaryFromJSON(jsonValue),
    );
  }

  /**
   */
  async vendorsSummaryRetrieve(
    requestParameters: VendorsSummaryRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Summary> {
    const response = await this.vendorsSummaryRetrieveRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async vendorsSummaryUpdateRaw(
    requestParameters: VendorsSummaryUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Summary>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling vendorsSummaryUpdate().',
      );
    }

    if (requestParameters['summary'] == null) {
      throw new runtime.RequiredError(
        'summary',
        'Required parameter "summary" was null or undefined when calling vendorsSummaryUpdate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/vendors/summary/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters['id'])),
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: SummaryToJSON(requestParameters['summary']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SummaryFromJSON(jsonValue),
    );
  }

  /**
   */
  async vendorsSummaryUpdate(
    requestParameters: VendorsSummaryUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Summary> {
    const response = await this.vendorsSummaryUpdateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Will take in a customer.io event webhook and handles email and sms events  email:     subscribe, unsubscribe - nothing sms     sent - create a message object also send a message to sendbird     bounced, failed - note down in user object
   */
  async vendorsWebhooksCioSmsOutgoingCreateRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/vendors/webhooks/cio-sms-outgoing`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Will take in a customer.io event webhook and handles email and sms events  email:     subscribe, unsubscribe - nothing sms     sent - create a message object also send a message to sendbird     bounced, failed - note down in user object
   */
  async vendorsWebhooksCioSmsOutgoingCreate(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.vendorsWebhooksCioSmsOutgoingCreateRaw(initOverrides);
  }

  /**
   */
  async vendorsWebhooksGenerateTractorscopeUrlsCreateRaw(
    requestParameters: VendorsWebhooksGenerateTractorscopeUrlsCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<TractorscopeUrlResponse>> {
    if (requestParameters['tractorscopeUrlReq'] == null) {
      throw new runtime.RequiredError(
        'tractorscopeUrlReq',
        'Required parameter "tractorscopeUrlReq" was null or undefined when calling vendorsWebhooksGenerateTractorscopeUrlsCreate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/vendors/webhooks/generate-tractorscope-urls/`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: TractorscopeUrlReqToJSON(requestParameters['tractorscopeUrlReq']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TractorscopeUrlResponseFromJSON(jsonValue),
    );
  }

  /**
   */
  async vendorsWebhooksGenerateTractorscopeUrlsCreate(
    requestParameters: VendorsWebhooksGenerateTractorscopeUrlsCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<TractorscopeUrlResponse> {
    const response =
      await this.vendorsWebhooksGenerateTractorscopeUrlsCreateRaw(
        requestParameters,
        initOverrides,
      );
    return await response.value();
  }

  /**
   */
  async vendorsWebhooksSendbirdSmsOutgoingCreateRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/vendors/webhooks/sendbird-sms-outgoing`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async vendorsWebhooksSendbirdSmsOutgoingCreate(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.vendorsWebhooksSendbirdSmsOutgoingCreateRaw(initOverrides);
  }

  /**
   */
  async vendorsWebhooksTwilioSmsIncomingCreateRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/vendors/webhooks/twilio-sms-incoming`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async vendorsWebhooksTwilioSmsIncomingCreate(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.vendorsWebhooksTwilioSmsIncomingCreateRaw(initOverrides);
  }
}
