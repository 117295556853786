import {
  CREATE_PATIENTS,
  CREATE_PROVIDERS,
  CREATE_WORKFLOWS,
} from '@/old/state/actions/types';

const initialState3 = {
  Patients: [],
  Providers: [],
  Workflows: [],
};

export const ClinicDetails = (state = initialState3, action) => {
  switch (action.type) {
    case CREATE_PATIENTS:
      return { ...state, Patients: [...action.payload.patients] };
    case CREATE_PROVIDERS:
      return { ...state, Providers: [...action.payload.providers] };
    case CREATE_WORKFLOWS:
      return { ...state, Workflows: [...action.payload.workflows] };
    default:
      return state;
  }
};
