/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `30` - ONE_CYCLE
 * * `60` - TWO_CYCLES
 * * `90` - THREE_CYCLES
 * @export
 */
export const DurationF28Enum = {
  NUMBER_30: 30,
  NUMBER_60: 60,
  NUMBER_90: 90,
} as const;
export type DurationF28Enum =
  (typeof DurationF28Enum)[keyof typeof DurationF28Enum];

export function DurationF28EnumFromJSON(json: any): DurationF28Enum {
  return DurationF28EnumFromJSONTyped(json, false);
}

export function DurationF28EnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): DurationF28Enum {
  return json as DurationF28Enum;
}

export function DurationF28EnumToJSON(value?: DurationF28Enum | null): any {
  return value as any;
}
