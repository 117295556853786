import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { PatientPatientsListRequest } from '@/schema';

export const PatientListTab = {
  ENROLLED: 'enrolled',
  UNENROLLED: 'unenrolled',
} as const;

export type PatientListTabEnum =
  (typeof PatientListTab)[keyof typeof PatientListTab];

export type SearchType = PatientPatientsListRequest['search'];

interface PatientListState {
  selectedTab: PatientListTabEnum;
  [PatientListTab.ENROLLED]: number;
  [PatientListTab.UNENROLLED]: number;
  setSelectedTab: (selectedTab: PatientListTabEnum) => void;
  setPatientListCount: (count: number, isEnrolled: boolean) => void;
}

export const usePatientListState = create<PatientListState>()(
  devtools(
    persist(
      (set) =>
        ({
          selectedTab: PatientListTab.ENROLLED,
          [PatientListTab.ENROLLED]: 0,
          [PatientListTab.UNENROLLED]: 0,
          setSelectedTab: (tab) => set({ selectedTab: tab }),
          setPatientListCount: (count, isEnrolled) =>
            isEnrolled
              ? set({ [PatientListTab.ENROLLED]: count })
              : set({ [PatientListTab.UNENROLLED]: count }),
        }) as PatientListState,
      { name: 'patient-list-state' },
    ),
    { name: 'patient-list-state' },
  ),
);
