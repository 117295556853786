/* tslint:disable */

/* eslint-disable */

/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { Clinic } from './Clinic';
import { ClinicFromJSON, ClinicFromJSONTyped, ClinicToJSON } from './Clinic';

/**
 *
 * @export
 * @interface Company
 */
export interface Company {
  /**
   *
   * @type {number}
   * @memberof Company
   */
  readonly id: number;
  /**
   *
   * @type {string}
   * @memberof Company
   */
  readonly name: string;
  /**
   *
   * @type {string}
   * @memberof Company
   */
  domain?: string;
  /**
   *
   * @type {Array<Clinic>}
   * @memberof Company
   */
  readonly clinics: Array<Clinic>;
}

/**
 * Check if a given object implements the Company interface.
 */
export function instanceOfCompany(value: object): boolean {
  if (!('id' in value)) return false;
  if (!('name' in value)) return false;
  if (!('clinics' in value)) return false;
  return true;
}

export function CompanyFromJSON(json: any): Company {
  return CompanyFromJSONTyped(json, false);
}

export function CompanyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Company {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    domain: json['domain'] == null ? undefined : json['domain'],
    clinics: (json['clinics'] as Array<any>).map(ClinicFromJSON),
  };
}

export function CompanyToJSON(value?: Company | null): any {
  if (value == null) {
    return value;
  }
  return {
    domain: value['domain'],
  };
}
