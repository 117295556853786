/* tslint:disable */

/* eslint-disable */

/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  ChatChannelInvite,
  InteractionLog,
  InteractionLogSerializerRequest,
  PatchedInteractionLogSerializerRequest,
  PatchedProviderMetadata,
  ProviderMetadata,
} from '../models/index';
import {
  ChatChannelInviteFromJSON,
  ChatChannelInviteToJSON,
  InteractionLogFromJSON,
  InteractionLogSerializerRequestFromJSON,
  InteractionLogSerializerRequestToJSON,
  InteractionLogToJSON,
  PatchedInteractionLogSerializerRequestFromJSON,
  PatchedInteractionLogSerializerRequestToJSON,
  PatchedProviderMetadataFromJSON,
  PatchedProviderMetadataToJSON,
  ProviderMetadataFromJSON,
  ProviderMetadataToJSON,
} from '../models/index';
import * as runtime from '../runtime';

export interface PtInteractionLogsCreateRequest {
  interactionLogSerializerRequest: InteractionLogSerializerRequest;
}

export interface PtInteractionLogsDestroyRequest {
  id: number;
}

export interface PtInteractionLogsListRequest {
  durationSecs?: number;
  durationSecsGt?: number;
  durationSecsLt?: number;
  endDatetime?: Date;
  interactedDatetime?: Date;
  interactedDatetimeGte?: Date;
  interactedDatetimeLte?: Date;
  patient?: number;
  provider?: number;
  startDatetime?: Date;
  types?: string;
}

export interface PtInteractionLogsPartialUpdateRequest {
  id: number;
  patchedInteractionLogSerializerRequest?: PatchedInteractionLogSerializerRequest;
}

export interface PtInteractionLogsRetrieveRequest {
  id: number;
}

export interface PtInteractionLogsUpdateRequest {
  id: number;
  interactionLogSerializerRequest: InteractionLogSerializerRequest;
}

export interface PtProvidersCreateRequest {
  providerMetadata?: ProviderMetadata;
}

export interface PtProvidersDestroyRequest {
  id: string;
}

export interface PtProvidersInviteToChatChannelCreateRequest {
  id: string;
  chatChannelInvite?: ChatChannelInvite;
}

export interface PtProvidersPartialUpdateRequest {
  id: string;
  patchedProviderMetadata?: PatchedProviderMetadata;
}

export interface PtProvidersRetrieveRequest {
  id: string;
}

export interface PtProvidersUpdateRequest {
  id: string;
  providerMetadata?: ProviderMetadata;
}

/**
 *
 */
export class PtApi extends runtime.BaseAPI {
  /**
   */
  async ptInteractionLogsCreateRaw(
    requestParameters: PtInteractionLogsCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<InteractionLog>> {
    if (requestParameters['interactionLogSerializerRequest'] == null) {
      throw new runtime.RequiredError(
        'interactionLogSerializerRequest',
        'Required parameter "interactionLogSerializerRequest" was null or undefined when calling ptInteractionLogsCreate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pt/interaction-logs/`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: InteractionLogSerializerRequestToJSON(
          requestParameters['interactionLogSerializerRequest'],
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InteractionLogFromJSON(jsonValue),
    );
  }

  /**
   */
  async ptInteractionLogsCreate(
    requestParameters: PtInteractionLogsCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<InteractionLog> {
    const response = await this.ptInteractionLogsCreateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async ptInteractionLogsDestroyRaw(
    requestParameters: PtInteractionLogsDestroyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling ptInteractionLogsDestroy().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pt/interaction-logs/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters['id'])),
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async ptInteractionLogsDestroy(
    requestParameters: PtInteractionLogsDestroyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.ptInteractionLogsDestroyRaw(requestParameters, initOverrides);
  }

  /**
   */
  async ptInteractionLogsListRaw(
    requestParameters: PtInteractionLogsListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<InteractionLog>>> {
    const queryParameters: any = {};

    if (requestParameters['durationSecs'] != null) {
      queryParameters['duration_secs'] = requestParameters['durationSecs'];
    }

    if (requestParameters['durationSecsGt'] != null) {
      queryParameters['duration_secs__gt'] =
        requestParameters['durationSecsGt'];
    }

    if (requestParameters['durationSecsLt'] != null) {
      queryParameters['duration_secs__lt'] =
        requestParameters['durationSecsLt'];
    }

    if (requestParameters['endDatetime'] != null) {
      queryParameters['end_datetime'] = (
        requestParameters['endDatetime'] as any
      ).toISOString();
    }

    if (requestParameters['interactedDatetime'] != null) {
      queryParameters['interacted_datetime'] = (
        requestParameters['interactedDatetime'] as any
      ).toISOString();
    }

    if (requestParameters['interactedDatetimeGte'] != null) {
      queryParameters['interacted_datetime__gte'] = (
        requestParameters['interactedDatetimeGte'] as any
      ).toISOString();
    }

    if (requestParameters['interactedDatetimeLte'] != null) {
      queryParameters['interacted_datetime__lte'] = (
        requestParameters['interactedDatetimeLte'] as any
      ).toISOString();
    }

    if (requestParameters['patient'] != null) {
      queryParameters['patient'] = requestParameters['patient'];
    }

    if (requestParameters['provider'] != null) {
      queryParameters['provider'] = requestParameters['provider'];
    }

    if (requestParameters['startDatetime'] != null) {
      queryParameters['start_datetime'] = (
        requestParameters['startDatetime'] as any
      ).toISOString();
    }

    if (requestParameters['types'] != null) {
      queryParameters['types'] = requestParameters['types'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pt/interaction-logs/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(InteractionLogFromJSON),
    );
  }

  /**
   */
  async ptInteractionLogsList(
    requestParameters: PtInteractionLogsListRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<InteractionLog>> {
    const response = await this.ptInteractionLogsListRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async ptInteractionLogsPartialUpdateRaw(
    requestParameters: PtInteractionLogsPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<InteractionLog>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling ptInteractionLogsPartialUpdate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pt/interaction-logs/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters['id'])),
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: PatchedInteractionLogSerializerRequestToJSON(
          requestParameters['patchedInteractionLogSerializerRequest'],
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InteractionLogFromJSON(jsonValue),
    );
  }

  /**
   */
  async ptInteractionLogsPartialUpdate(
    requestParameters: PtInteractionLogsPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<InteractionLog> {
    const response = await this.ptInteractionLogsPartialUpdateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async ptInteractionLogsRetrieveRaw(
    requestParameters: PtInteractionLogsRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<InteractionLog>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling ptInteractionLogsRetrieve().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pt/interaction-logs/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters['id'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InteractionLogFromJSON(jsonValue),
    );
  }

  /**
   */
  async ptInteractionLogsRetrieve(
    requestParameters: PtInteractionLogsRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<InteractionLog> {
    const response = await this.ptInteractionLogsRetrieveRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async ptInteractionLogsUpdateRaw(
    requestParameters: PtInteractionLogsUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<InteractionLog>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling ptInteractionLogsUpdate().',
      );
    }

    if (requestParameters['interactionLogSerializerRequest'] == null) {
      throw new runtime.RequiredError(
        'interactionLogSerializerRequest',
        'Required parameter "interactionLogSerializerRequest" was null or undefined when calling ptInteractionLogsUpdate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pt/interaction-logs/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters['id'])),
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: InteractionLogSerializerRequestToJSON(
          requestParameters['interactionLogSerializerRequest'],
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InteractionLogFromJSON(jsonValue),
    );
  }

  /**
   */
  async ptInteractionLogsUpdate(
    requestParameters: PtInteractionLogsUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<InteractionLog> {
    const response = await this.ptInteractionLogsUpdateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async ptProvidersCreateRaw(
    requestParameters: PtProvidersCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ProviderMetadata>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pt/providers/`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ProviderMetadataToJSON(requestParameters['providerMetadata']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProviderMetadataFromJSON(jsonValue),
    );
  }

  /**
   */
  async ptProvidersCreate(
    requestParameters: PtProvidersCreateRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ProviderMetadata> {
    const response = await this.ptProvidersCreateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async ptProvidersDestroyRaw(
    requestParameters: PtProvidersDestroyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling ptProvidersDestroy().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pt/providers/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters['id'])),
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async ptProvidersDestroy(
    requestParameters: PtProvidersDestroyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.ptProvidersDestroyRaw(requestParameters, initOverrides);
  }

  /**
   * We directly go to Sendbird and add the user ot the chat without going through the full SaRA invite process since we dont want to add the provider to the patient\'s careteam in this case.
   */
  async ptProvidersInviteToChatChannelCreateRaw(
    requestParameters: PtProvidersInviteToChatChannelCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling ptProvidersInviteToChatChannelCreate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pt/providers/{id}/invite-to-chat-channel/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters['id'])),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ChatChannelInviteToJSON(requestParameters['chatChannelInvite']),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * We directly go to Sendbird and add the user ot the chat without going through the full SaRA invite process since we dont want to add the provider to the patient\'s careteam in this case.
   */
  async ptProvidersInviteToChatChannelCreate(
    requestParameters: PtProvidersInviteToChatChannelCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.ptProvidersInviteToChatChannelCreateRaw(
      requestParameters,
      initOverrides,
    );
  }

  /**
   */
  async ptProvidersListRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<ProviderMetadata>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pt/providers/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(ProviderMetadataFromJSON),
    );
  }

  /**
   */
  async ptProvidersList(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<ProviderMetadata>> {
    const response = await this.ptProvidersListRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async ptProvidersPartialUpdateRaw(
    requestParameters: PtProvidersPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ProviderMetadata>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling ptProvidersPartialUpdate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pt/providers/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters['id'])),
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: PatchedProviderMetadataToJSON(
          requestParameters['patchedProviderMetadata'],
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProviderMetadataFromJSON(jsonValue),
    );
  }

  /**
   */
  async ptProvidersPartialUpdate(
    requestParameters: PtProvidersPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ProviderMetadata> {
    const response = await this.ptProvidersPartialUpdateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async ptProvidersRetrieveRaw(
    requestParameters: PtProvidersRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ProviderMetadata>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling ptProvidersRetrieve().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pt/providers/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters['id'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProviderMetadataFromJSON(jsonValue),
    );
  }

  /**
   */
  async ptProvidersRetrieve(
    requestParameters: PtProvidersRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ProviderMetadata> {
    const response = await this.ptProvidersRetrieveRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async ptProvidersUpdateRaw(
    requestParameters: PtProvidersUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ProviderMetadata>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling ptProvidersUpdate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pt/providers/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters['id'])),
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: ProviderMetadataToJSON(requestParameters['providerMetadata']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProviderMetadataFromJSON(jsonValue),
    );
  }

  /**
   */
  async ptProvidersUpdate(
    requestParameters: PtProvidersUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ProviderMetadata> {
    const response = await this.ptProvidersUpdateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}
