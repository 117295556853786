import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { initBridge } from '@/old/utils/bridge';

import { AppProviders } from './components/app-providers';
import './globals.css';

const rootEl = createRoot(document.getElementById('root') as HTMLElement);

(async () => {
  // Import Bridge SDK if running within EHR
  // Must be loaded before running application
  const promises = [
    initBridge(),
    asyncWithLDProvider({
      clientSideID: import.meta.env.VITE_LAUNCHDARKLEY_CLIENTSIDE_ID,
      timeout: 5,
    }),
  ] as const;

  const [, LDProvider] = await Promise.all(promises);

  rootEl.render(
    <LDProvider>
      <StrictMode>
        <AppProviders />
      </StrictMode>
    </LDProvider>,
  );
})();
