/* tslint:disable */

/* eslint-disable */

/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface ClinicMembership
 */
export interface ClinicMembership {
  /**
   *
   * @type {string}
   * @memberof ClinicMembership
   */
  readonly clinic: string;
  /**
   *
   * @type {boolean}
   * @memberof ClinicMembership
   */
  isActive?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ClinicMembership
   */
  isDefault?: boolean;
}

/**
 * Check if a given object implements the ClinicMembership interface.
 */
export function instanceOfClinicMembership(value: object): boolean {
  if (!('clinic' in value)) return false;
  return true;
}

export function ClinicMembershipFromJSON(json: any): ClinicMembership {
  return ClinicMembershipFromJSONTyped(json, false);
}

export function ClinicMembershipFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ClinicMembership {
  if (json == null) {
    return json;
  }
  return {
    clinic: json['clinic'],
    isActive: json['is_active'] == null ? undefined : json['is_active'],
    isDefault: json['is_default'] == null ? undefined : json['is_default'],
  };
}

export function ClinicMembershipToJSON(value?: ClinicMembership | null): any {
  if (value == null) {
    return value;
  }
  return {
    is_active: value['isActive'],
    is_default: value['isDefault'],
  };
}
