/* tslint:disable */

/* eslint-disable */

/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface PatchedPatientCareTeam
 */
export interface PatchedPatientCareTeam {
  /**
   * The email of the primary provider.
   * @type {string}
   * @memberof PatchedPatientCareTeam
   */
  primaryProvider?: string;
  /**
   * A list of emails of the secondary providers.
   * @type {Array<string>}
   * @memberof PatchedPatientCareTeam
   */
  secondaryProviders?: Array<string>;
}

/**
 * Check if a given object implements the PatchedPatientCareTeam interface.
 */
export function instanceOfPatchedPatientCareTeam(value: object): boolean {
  return true;
}

export function PatchedPatientCareTeamFromJSON(
  json: any,
): PatchedPatientCareTeam {
  return PatchedPatientCareTeamFromJSONTyped(json, false);
}

export function PatchedPatientCareTeamFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PatchedPatientCareTeam {
  if (json == null) {
    return json;
  }
  return {
    primaryProvider:
      json['primary_provider'] == null ? undefined : json['primary_provider'],
    secondaryProviders:
      json['secondary_providers'] == null
        ? undefined
        : json['secondary_providers'],
  };
}

export function PatchedPatientCareTeamToJSON(
  value?: PatchedPatientCareTeam | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    primary_provider: value['primaryProvider'],
    secondary_providers: value['secondaryProviders'],
  };
}
