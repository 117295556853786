/* tslint:disable */

/* eslint-disable */

/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface TractorscopeUrlResponse
 */
export interface TractorscopeUrlResponse {
  /**
   *
   * @type {string}
   * @memberof TractorscopeUrlResponse
   */
  providerDashboardId: string;
  /**
   *
   * @type {string}
   * @memberof TractorscopeUrlResponse
   */
  providerDashboardUrl: string;
  /**
   *
   * @type {string}
   * @memberof TractorscopeUrlResponse
   */
  ownerDashboardId?: string;
  /**
   *
   * @type {string}
   * @memberof TractorscopeUrlResponse
   */
  ownerDashboardUrl?: string;
}

/**
 * Check if a given object implements the TractorscopeUrlResponse interface.
 */
export function instanceOfTractorscopeUrlResponse(value: object): boolean {
  if (!('providerDashboardId' in value)) return false;
  if (!('providerDashboardUrl' in value)) return false;
  return true;
}

export function TractorscopeUrlResponseFromJSON(
  json: any,
): TractorscopeUrlResponse {
  return TractorscopeUrlResponseFromJSONTyped(json, false);
}

export function TractorscopeUrlResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TractorscopeUrlResponse {
  if (json == null) {
    return json;
  }
  return {
    providerDashboardId: json['provider_dashboard_id'],
    providerDashboardUrl: json['provider_dashboard_url'],
    ownerDashboardId:
      json['owner_dashboard_id'] == null
        ? undefined
        : json['owner_dashboard_id'],
    ownerDashboardUrl:
      json['owner_dashboard_url'] == null
        ? undefined
        : json['owner_dashboard_url'],
  };
}

export function TractorscopeUrlResponseToJSON(
  value?: TractorscopeUrlResponse | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    provider_dashboard_id: value['providerDashboardId'],
    provider_dashboard_url: value['providerDashboardUrl'],
    owner_dashboard_id: value['ownerDashboardId'],
    owner_dashboard_url: value['ownerDashboardUrl'],
  };
}
