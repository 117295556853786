import { UPDATE_HEADER } from '@/old/state/actions/types';

const initialState = {
  header: '',
};

export function appBarReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_HEADER:
      return {
        ...state,
        header: action.payload.header,
        // ...[action.payload.id],
        // [action.payload.id]: { ...action.payload }
      };

    default:
      return state;
  }
}
