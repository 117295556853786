import { createSelector } from '@reduxjs/toolkit';

import {
  CLOSE_FORGOT_PASSWORD,
  LOGIN_PENDING,
  OPEN_FORGOT_PASSWORD,
  RESET_SESSION_DATA,
  SET_CURRENT_PATIENT_ID,
  STORE_SESSION_DATA,
} from '@/old/state/actions/types';

const initialState1 = {
  loginPending: false,
  open: false,
};
export const Login = (state = initialState1, action) => {
  switch (action.type) {
    case LOGIN_PENDING:
      return { ...state, ...action.payload };
    case OPEN_FORGOT_PASSWORD:
      return { ...state, ...action.payload };
    case CLOSE_FORGOT_PASSWORD:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
const initialState2 = {
  token: '',
  currentPatientId: '',
  user: { user_type: '', custom_branding: { color_primary: '' } },
  api: { intercom_user_hash: '' },
};

export const Session = (state = initialState2, action) => {
  switch (action.type) {
    case RESET_SESSION_DATA:
      return { ...initialState2 };
    case STORE_SESSION_DATA:
      return { ...state, ...action.payload.data };
    case SET_CURRENT_PATIENT_ID:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export const sessionSelector = (state) => state['Session'];
export const loggedUserSelector = createSelector(
  sessionSelector,
  (session) => session?.user,
);
export const loggedUserIdSelector = createSelector(
  loggedUserSelector,
  (user) => user?.id ?? null,
);
export const loggedUserEmailSelector = createSelector(
  loggedUserSelector,
  (user) => user?.email,
);
