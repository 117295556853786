/* tslint:disable */

/* eslint-disable */

/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface PatientRtmEnrollCreate201Response
 */
export interface PatientRtmEnrollCreate201Response {
  /**
   * Indicates the enrollment message for the patient
   * @type {string}
   * @memberof PatientRtmEnrollCreate201Response
   */
  message?: string;
}

/**
 * Check if a given object implements the PatientRtmEnrollCreate201Response interface.
 */
export function instanceOfPatientRtmEnrollCreate201Response(
  value: object,
): boolean {
  return true;
}

export function PatientRtmEnrollCreate201ResponseFromJSON(
  json: any,
): PatientRtmEnrollCreate201Response {
  return PatientRtmEnrollCreate201ResponseFromJSONTyped(json, false);
}

export function PatientRtmEnrollCreate201ResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PatientRtmEnrollCreate201Response {
  if (json == null) {
    return json;
  }
  return {
    message: json['message'] == null ? undefined : json['message'],
  };
}

export function PatientRtmEnrollCreate201ResponseToJSON(
  value?: PatientRtmEnrollCreate201Response | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    message: value['message'],
  };
}
