import { EllipsisVerticalIcon } from 'lucide-react';

import { Button } from './ui/button';
import { DropdownMenuTrigger } from './ui/dropdown-menu';

export const EllipsisMenuTrigger = () => (
  <DropdownMenuTrigger asChild>
    <Button variant="ghost" size="icon">
      <EllipsisVerticalIcon />
    </Button>
  </DropdownMenuTrigger>
);
