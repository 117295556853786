/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `patient` - Patient
 * * `pt` - PT
 * @export
 */
export const UserTypeEnum = {
  Patient: 'patient',
  Pt: 'pt',
} as const;
export type UserTypeEnum = (typeof UserTypeEnum)[keyof typeof UserTypeEnum];

export function UserTypeEnumFromJSON(json: any): UserTypeEnum {
  return UserTypeEnumFromJSONTyped(json, false);
}

export function UserTypeEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserTypeEnum {
  return json as UserTypeEnum;
}

export function UserTypeEnumToJSON(value?: UserTypeEnum | null): any {
  return value as any;
}
