/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `30` - ONE_CYCLE
 * * `60` - TWO_CYCLES
 * * `90` - THREE_CYCLES
 * @export
 */
export const ExtendDurationEnum = {
  NUMBER_30: 30,
  NUMBER_60: 60,
  NUMBER_90: 90,
} as const;
export type ExtendDurationEnum =
  (typeof ExtendDurationEnum)[keyof typeof ExtendDurationEnum];

export function ExtendDurationEnumFromJSON(json: any): ExtendDurationEnum {
  return ExtendDurationEnumFromJSONTyped(json, false);
}

export function ExtendDurationEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ExtendDurationEnum {
  return json as ExtendDurationEnum;
}

export function ExtendDurationEnumToJSON(
  value?: ExtendDurationEnum | null,
): any {
  return value as any;
}
