/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `YES` - YES
 * * `NO` - NO
 * * `MAYBE` - MAYBE
 * @export
 */
export const BillingInstanceBulkCancelWillChargePatientEnum = {
  Yes: 'YES',
  No: 'NO',
  Maybe: 'MAYBE',
} as const;
export type BillingInstanceBulkCancelWillChargePatientEnum =
  (typeof BillingInstanceBulkCancelWillChargePatientEnum)[keyof typeof BillingInstanceBulkCancelWillChargePatientEnum];

export function BillingInstanceBulkCancelWillChargePatientEnumFromJSON(
  json: any,
): BillingInstanceBulkCancelWillChargePatientEnum {
  return BillingInstanceBulkCancelWillChargePatientEnumFromJSONTyped(
    json,
    false,
  );
}

export function BillingInstanceBulkCancelWillChargePatientEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BillingInstanceBulkCancelWillChargePatientEnum {
  return json as BillingInstanceBulkCancelWillChargePatientEnum;
}

export function BillingInstanceBulkCancelWillChargePatientEnumToJSON(
  value?: BillingInstanceBulkCancelWillChargePatientEnum | null,
): any {
  return value as any;
}
