// #region Auth
export const EMAIL_VERIFY_URL = '/email-verify/:verifyId';
export const LOGIN_URL = '/login';
export const LOGOUT_URL = '/logout';
export const PASSWORD_RESET_URL = '/password-reset/:token';
export const REGISTRATION_URL = '/registration';
export const SSO_AUTH_URL = '/sso-auth';
// #endregion

export const BILLING_URL = '/billing';
export const BRIDGE_APP_URL = '/bridge-app';
export const BRIDGE_SSO_URL = '/bridge-sso';
export const INTERACTION_LOG_URL = '/logs';
export const METRICS_URL = '/metrics';
export const PATIENT_LIST_URL = '/patient-list';
export const SUPPORT_URL = '/support';
