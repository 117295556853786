import { addDays, addMinutes, intlFormat, intlFormatDistance } from 'date-fns';
import { format, parse, parseISO } from 'date-fns/fp';

const intlLongFormatOptions: Parameters<typeof intlFormat>[1] = {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  hour12: true,
};

const intlShortFormatOptions: Parameters<typeof intlFormat>[1] = {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
};

export const USER_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const offsetTimezoneToLocal = (date: Date) =>
  addMinutes(date, date.getTimezoneOffset());

export const parseMonth = parse(new Date(), 'yyyy-MM');

export const parseDate = parse(new Date(), 'yyyy-MM-dd');

export const formatMonth = format('MMMM yyyy');

export const readableMonth = (date: string) => formatMonth(parseMonth(date));

export const formatDateShort = (date: Date) =>
  intlFormat(date, intlShortFormatOptions);

export const formatISODateShort = (isoDate: string) =>
  formatDateShort(parseISO(isoDate));

export const formatDateLong = (date: Date) =>
  intlFormat(date, intlLongFormatOptions);

export const formatISODateLong = (isoDate: string) =>
  formatDateLong(parseISO(isoDate));

export const dateDaysFromDate = (days: number, date = new Date()) =>
  addDays(date, days);

export const formatDateDaysFromDate = (days: number, date = new Date()) =>
  formatDateShort(dateDaysFromDate(days, date));

export const relativeFormatDaysFromDate = (
  days: number,
  baseDate = new Date(),
) =>
  intlFormatDistance(dateDaysFromDate(days, baseDate), baseDate, {
    unit: 'day',
  });
