import {
  ErrorBoundary as SentryErrorBoundary,
  wrapCreateBrowserRouterV7,
} from '@sentry/react';
import {
  DefaultOptions,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { Provider } from 'react-redux';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router';
import { PersistGate } from 'redux-persist/es/integration/react';

import { persistor, store } from '@/old/state/store';
import PrivateRoute from '@/old/views/components/PrivateRoute';
import {
  BILLING_URL,
  BRIDGE_APP_URL,
  BRIDGE_SSO_URL,
  EMAIL_VERIFY_URL,
  INTERACTION_LOG_URL,
  LOGIN_URL,
  LOGOUT_URL,
  METRICS_URL,
  PASSWORD_RESET_URL,
  PATIENT_LIST_URL,
  REGISTRATION_URL,
  SSO_AUTH_URL,
  SUPPORT_URL,
} from '@/routes/app-routes';
import { ErrorPage } from '@/routes/error-page';
import { RootLayout } from '@/routes/root-layout';
import { UnauthorizedError } from '@/utils/fetch-utils';
import '@/utils/sentry';

import { AppLayout } from './app-layout';
import { AuthLayout } from './auth/auth-layout';
import { Toaster } from './ui/sonner';
import { TooltipProvider } from './ui/tooltip';

const tanstackQueryRetryOptions: DefaultOptions['queries'] &
  DefaultOptions['mutations'] = {
  retry: (failCount: number, error: Error) => {
    if (error instanceof UnauthorizedError || failCount >= 3) {
      return false;
    }
    return true;
  },
  retryDelay: 1000,
  refetchOnWindowFocus: false,
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      ...tanstackQueryRetryOptions,
    },
    mutations: {
      ...tanstackQueryRetryOptions,
    },
  },
});

const createRouter = wrapCreateBrowserRouterV7(createBrowserRouter);

const router = createRouter([
  {
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      // Redirects & unwrapped routes
      {
        path: SSO_AUTH_URL,
        lazy: () =>
          import('@/routes/sso-auth').then((m) => ({
            Component: m.SSOAuthPage,
          })),
      },
      {
        path: BRIDGE_SSO_URL,
        lazy: () =>
          import('@/routes/bridge-sso-tile-page').then((m) => ({
            Component: m.BridgeSSOTilePage,
          })),
      },
      {
        path: BRIDGE_APP_URL,
        element: <Navigate to={PATIENT_LIST_URL} />,
      },
      // Auth routes
      {
        element: <AuthLayout />,
        children: [
          {
            path: REGISTRATION_URL,
            lazy: () =>
              import('@/routes/registration-page').then((m) => ({
                Component: m.RegistrationPage,
              })),
          },

          {
            path: LOGIN_URL,
            lazy: () =>
              import('@/routes/login-page').then((m) => ({
                Component: m.LoginPage,
              })),
          },
          {
            path: LOGOUT_URL,
            lazy: () =>
              import('@/routes/login-page').then((m) => ({
                Component: m.LoginPage,
              })),
          },
          {
            path: PASSWORD_RESET_URL,
            lazy: () =>
              import('@/routes/reset-password-page').then((m) => ({
                Component: m.ResetPasswordPage,
              })),
          },
          {
            path: EMAIL_VERIFY_URL,
            lazy: () =>
              import('@/routes/email-verify-page').then((m) => ({
                Component: m.EmailVerifyPage,
              })),
          },
        ],
      },
      // Private routes
      {
        element: <AppLayout />,
        children: [
          {
            errorElement: <ErrorPage />,
            children: [
              {
                element: <PrivateRoute />,
                children: [
                  {
                    path: PATIENT_LIST_URL,
                    // element: <PatientListPage />,
                    lazy: () =>
                      import('@/routes/patient-list-page').then((m) => ({
                        Component: m.PatientListPage,
                      })),
                  },
                  {
                    path: BILLING_URL,
                    lazy: () =>
                      import('@/routes/billing-page').then((m) => ({
                        Component: m.BillingPage,
                      })),
                  },
                  {
                    path: METRICS_URL,
                    lazy: () =>
                      import('@/routes/metrics-page').then((m) => ({
                        Component: m.MetricsPage,
                      })),
                  },
                  {
                    path: `${INTERACTION_LOG_URL}/:patientId`,
                    lazy: () =>
                      import('@/routes/interaction-log-page').then((m) => ({
                        Component: m.InteractionLogsPage,
                      })),
                  },
                  {
                    path: SUPPORT_URL,
                    lazy: () =>
                      import('@/routes/support-page').then((m) => ({
                        Component: m.SupportPage,
                      })),
                  },
                ],
              },
            ],
          },
        ],
      },
      // Fallback route
      {
        path: '*',
        element: <Navigate to={LOGIN_URL} />,
      },
    ],
  },
]);

export const AppProviders = () => {
  return (
    <SentryErrorBoundary fallback={ErrorPage} showDialog>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <TooltipProvider>
              <Toaster />

              <RouterProvider router={router} />
            </TooltipProvider>
          </PersistGate>
        </Provider>
      </QueryClientProvider>
    </SentryErrorBoundary>
  );
};
