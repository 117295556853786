/* tslint:disable */

/* eslint-disable */

/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  ChatChannel,
  EMRPatientRecord,
  PaginatedPatientList,
  PatchedChatChannel,
  PatchedPatient,
  PatchedPatientCareTeam,
  Patient,
  PatientCareTeam,
  PatientCreate,
  PatientListViaPost,
  PatientRTMEnrollment,
  PatientRtmEnrollCreate201Response,
  PatientRtmReEnrollCreate200Response,
} from '../models/index';
import {
  ChatChannelFromJSON,
  ChatChannelToJSON,
  EMRPatientRecordFromJSON,
  EMRPatientRecordToJSON,
  PaginatedPatientListFromJSON,
  PaginatedPatientListToJSON,
  PatchedChatChannelFromJSON,
  PatchedChatChannelToJSON,
  PatchedPatientCareTeamFromJSON,
  PatchedPatientCareTeamToJSON,
  PatchedPatientFromJSON,
  PatchedPatientToJSON,
  PatientCareTeamFromJSON,
  PatientCareTeamToJSON,
  PatientCreateFromJSON,
  PatientCreateToJSON,
  PatientFromJSON,
  PatientListViaPostFromJSON,
  PatientListViaPostToJSON,
  PatientRTMEnrollmentFromJSON,
  PatientRTMEnrollmentToJSON,
  PatientRtmEnrollCreate201ResponseFromJSON,
  PatientRtmEnrollCreate201ResponseToJSON,
  PatientRtmReEnrollCreate200ResponseFromJSON,
  PatientRtmReEnrollCreate200ResponseToJSON,
  PatientToJSON,
} from '../models/index';
import * as runtime from '../runtime';

export interface PatientChatChannelCreateRequest {
  chatChannel?: ChatChannel;
}

export interface PatientChatChannelDestroyRequest {
  channelUrl: string;
}

export interface PatientChatChannelPartialUpdateRequest {
  channelUrl: string;
  patchedChatChannel?: PatchedChatChannel;
}

export interface PatientChatChannelRetrieveRequest {
  channelUrl: string;
}

export interface PatientChatChannelUpdateRequest {
  channelUrl: string;
  chatChannel?: ChatChannel;
}

export interface PatientEmrPatientDataCreateRequest {
  eMRPatientRecord: EMRPatientRecord;
}

export interface PatientPatientsCreateRequest {
  patient?: Patient;
}

export interface PatientPatientsDestroyRequest {
  id: string;
}

export interface PatientPatientsListRequest {
  birthDate?: Date;
  currentIntlogSecsMax?: number;
  currentIntlogSecsMin?: number;
  currentRtmcycleResponsesCountMax?: number;
  currentRtmcycleResponsesCountMin?: number;
  hasSynchronousAction?: boolean;
  isCurrentlyEnrolled?: boolean;
  ordering?: string;
  page?: number;
  pageSize?: number;
  providerId?: number;
  search?: string;
  sendbirdChannelUrls?: Array<string>;
}

export interface PatientPatientsListViaPostCreateRequest {
  birthDate?: Date;
  currentIntlogSecsMax?: number;
  currentIntlogSecsMin?: number;
  currentRtmcycleResponsesCountMax?: number;
  currentRtmcycleResponsesCountMin?: number;
  hasSynchronousAction?: boolean;
  isCurrentlyEnrolled?: boolean;
  ordering?: string;
  page?: number;
  pageSize?: number;
  providerId?: number;
  search?: string;
  sendbirdChannelUrls?: Array<string>;
  patientListViaPost?: PatientListViaPost;
}

export interface PatientPatientsPartialUpdateRequest {
  id: string;
  patchedPatient?: PatchedPatient;
}

export interface PatientPatientsRetrieveRequest {
  id: string;
}

export interface PatientPatientsUpdateRequest {
  id: string;
  patient?: Patient;
}

export interface PatientRtmEnrollCreateRequest {
  patientCreate: PatientCreate;
}

export interface PatientRtmReEnrollCreateRequest {
  patientId: string;
  patientRTMEnrollment: PatientRTMEnrollment;
}

export interface PatientUpdateCareTeamPartialUpdateRequest {
  userId: number;
  patchedPatientCareTeam?: PatchedPatientCareTeam;
}

export interface PatientUpdateCareTeamUpdateRequest {
  userId: number;
  patientCareTeam: PatientCareTeam;
}

/**
 *
 */
export class PatientApi extends runtime.BaseAPI {
  /**
   */
  async patientChatChannelCreateRaw(
    requestParameters: PatientChatChannelCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ChatChannel>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/chat-channel/`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ChatChannelToJSON(requestParameters['chatChannel']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ChatChannelFromJSON(jsonValue),
    );
  }

  /**
   */
  async patientChatChannelCreate(
    requestParameters: PatientChatChannelCreateRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ChatChannel> {
    const response = await this.patientChatChannelCreateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async patientChatChannelDestroyRaw(
    requestParameters: PatientChatChannelDestroyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['channelUrl'] == null) {
      throw new runtime.RequiredError(
        'channelUrl',
        'Required parameter "channelUrl" was null or undefined when calling patientChatChannelDestroy().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/chat-channel/{channel_url}/`.replace(
          `{${'channel_url'}}`,
          encodeURIComponent(String(requestParameters['channelUrl'])),
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async patientChatChannelDestroy(
    requestParameters: PatientChatChannelDestroyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.patientChatChannelDestroyRaw(requestParameters, initOverrides);
  }

  /**
   */
  async patientChatChannelListRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<ChatChannel>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/chat-channel/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(ChatChannelFromJSON),
    );
  }

  /**
   */
  async patientChatChannelList(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<ChatChannel>> {
    const response = await this.patientChatChannelListRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async patientChatChannelPartialUpdateRaw(
    requestParameters: PatientChatChannelPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ChatChannel>> {
    if (requestParameters['channelUrl'] == null) {
      throw new runtime.RequiredError(
        'channelUrl',
        'Required parameter "channelUrl" was null or undefined when calling patientChatChannelPartialUpdate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/chat-channel/{channel_url}/`.replace(
          `{${'channel_url'}}`,
          encodeURIComponent(String(requestParameters['channelUrl'])),
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: PatchedChatChannelToJSON(requestParameters['patchedChatChannel']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ChatChannelFromJSON(jsonValue),
    );
  }

  /**
   */
  async patientChatChannelPartialUpdate(
    requestParameters: PatientChatChannelPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ChatChannel> {
    const response = await this.patientChatChannelPartialUpdateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async patientChatChannelRetrieveRaw(
    requestParameters: PatientChatChannelRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ChatChannel>> {
    if (requestParameters['channelUrl'] == null) {
      throw new runtime.RequiredError(
        'channelUrl',
        'Required parameter "channelUrl" was null or undefined when calling patientChatChannelRetrieve().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/chat-channel/{channel_url}/`.replace(
          `{${'channel_url'}}`,
          encodeURIComponent(String(requestParameters['channelUrl'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ChatChannelFromJSON(jsonValue),
    );
  }

  /**
   */
  async patientChatChannelRetrieve(
    requestParameters: PatientChatChannelRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ChatChannel> {
    const response = await this.patientChatChannelRetrieveRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async patientChatChannelUpdateRaw(
    requestParameters: PatientChatChannelUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ChatChannel>> {
    if (requestParameters['channelUrl'] == null) {
      throw new runtime.RequiredError(
        'channelUrl',
        'Required parameter "channelUrl" was null or undefined when calling patientChatChannelUpdate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/chat-channel/{channel_url}/`.replace(
          `{${'channel_url'}}`,
          encodeURIComponent(String(requestParameters['channelUrl'])),
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: ChatChannelToJSON(requestParameters['chatChannel']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ChatChannelFromJSON(jsonValue),
    );
  }

  /**
   */
  async patientChatChannelUpdate(
    requestParameters: PatientChatChannelUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ChatChannel> {
    const response = await this.patientChatChannelUpdateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async patientEmrPatientDataCreateRaw(
    requestParameters: PatientEmrPatientDataCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EMRPatientRecord>> {
    if (requestParameters['eMRPatientRecord'] == null) {
      throw new runtime.RequiredError(
        'eMRPatientRecord',
        'Required parameter "eMRPatientRecord" was null or undefined when calling patientEmrPatientDataCreate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/emr-patient-data/`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: EMRPatientRecordToJSON(requestParameters['eMRPatientRecord']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EMRPatientRecordFromJSON(jsonValue),
    );
  }

  /**
   */
  async patientEmrPatientDataCreate(
    requestParameters: PatientEmrPatientDataCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EMRPatientRecord> {
    const response = await this.patientEmrPatientDataCreateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async patientPatientsCreateRaw(
    requestParameters: PatientPatientsCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Patient>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/patients/`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PatientToJSON(requestParameters['patient']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PatientFromJSON(jsonValue),
    );
  }

  /**
   */
  async patientPatientsCreate(
    requestParameters: PatientPatientsCreateRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Patient> {
    const response = await this.patientPatientsCreateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async patientPatientsDestroyRaw(
    requestParameters: PatientPatientsDestroyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling patientPatientsDestroy().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/patients/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters['id'])),
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async patientPatientsDestroy(
    requestParameters: PatientPatientsDestroyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.patientPatientsDestroyRaw(requestParameters, initOverrides);
  }

  /**
   */
  async patientPatientsListRaw(
    requestParameters: PatientPatientsListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaginatedPatientList>> {
    const queryParameters: any = {};

    if (requestParameters['birthDate'] != null) {
      queryParameters['birth_date'] = (requestParameters['birthDate'] as any)
        .toISOString()
        .substring(0, 10);
    }

    if (requestParameters['currentIntlogSecsMax'] != null) {
      queryParameters['current_intlog_secs_max'] =
        requestParameters['currentIntlogSecsMax'];
    }

    if (requestParameters['currentIntlogSecsMin'] != null) {
      queryParameters['current_intlog_secs_min'] =
        requestParameters['currentIntlogSecsMin'];
    }

    if (requestParameters['currentRtmcycleResponsesCountMax'] != null) {
      queryParameters['current_rtmcycle_responses_count_max'] =
        requestParameters['currentRtmcycleResponsesCountMax'];
    }

    if (requestParameters['currentRtmcycleResponsesCountMin'] != null) {
      queryParameters['current_rtmcycle_responses_count_min'] =
        requestParameters['currentRtmcycleResponsesCountMin'];
    }

    if (requestParameters['hasSynchronousAction'] != null) {
      queryParameters['has_synchronous_action'] =
        requestParameters['hasSynchronousAction'];
    }

    if (requestParameters['isCurrentlyEnrolled'] != null) {
      queryParameters['is_currently_enrolled'] =
        requestParameters['isCurrentlyEnrolled'];
    }

    if (requestParameters['ordering'] != null) {
      queryParameters['ordering'] = requestParameters['ordering'];
    }

    if (requestParameters['page'] != null) {
      queryParameters['page'] = requestParameters['page'];
    }

    if (requestParameters['pageSize'] != null) {
      queryParameters['page_size'] = requestParameters['pageSize'];
    }

    if (requestParameters['providerId'] != null) {
      queryParameters['provider_id'] = requestParameters['providerId'];
    }

    if (requestParameters['search'] != null) {
      queryParameters['search'] = requestParameters['search'];
    }

    if (requestParameters['sendbirdChannelUrls'] != null) {
      queryParameters['sendbird_channel_urls'] = requestParameters[
        'sendbirdChannelUrls'
      ]!.join(runtime.COLLECTION_FORMATS['csv']);
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/patients/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaginatedPatientListFromJSON(jsonValue),
    );
  }

  /**
   */
  async patientPatientsList(
    requestParameters: PatientPatientsListRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaginatedPatientList> {
    const response = await this.patientPatientsListRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async patientPatientsListViaPostCreateRaw(
    requestParameters: PatientPatientsListViaPostCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaginatedPatientList>> {
    const queryParameters: any = {};

    if (requestParameters['birthDate'] != null) {
      queryParameters['birth_date'] = (requestParameters['birthDate'] as any)
        .toISOString()
        .substring(0, 10);
    }

    if (requestParameters['currentIntlogSecsMax'] != null) {
      queryParameters['current_intlog_secs_max'] =
        requestParameters['currentIntlogSecsMax'];
    }

    if (requestParameters['currentIntlogSecsMin'] != null) {
      queryParameters['current_intlog_secs_min'] =
        requestParameters['currentIntlogSecsMin'];
    }

    if (requestParameters['currentRtmcycleResponsesCountMax'] != null) {
      queryParameters['current_rtmcycle_responses_count_max'] =
        requestParameters['currentRtmcycleResponsesCountMax'];
    }

    if (requestParameters['currentRtmcycleResponsesCountMin'] != null) {
      queryParameters['current_rtmcycle_responses_count_min'] =
        requestParameters['currentRtmcycleResponsesCountMin'];
    }

    if (requestParameters['hasSynchronousAction'] != null) {
      queryParameters['has_synchronous_action'] =
        requestParameters['hasSynchronousAction'];
    }

    if (requestParameters['isCurrentlyEnrolled'] != null) {
      queryParameters['is_currently_enrolled'] =
        requestParameters['isCurrentlyEnrolled'];
    }

    if (requestParameters['ordering'] != null) {
      queryParameters['ordering'] = requestParameters['ordering'];
    }

    if (requestParameters['page'] != null) {
      queryParameters['page'] = requestParameters['page'];
    }

    if (requestParameters['pageSize'] != null) {
      queryParameters['page_size'] = requestParameters['pageSize'];
    }

    if (requestParameters['providerId'] != null) {
      queryParameters['provider_id'] = requestParameters['providerId'];
    }

    if (requestParameters['search'] != null) {
      queryParameters['search'] = requestParameters['search'];
    }

    if (requestParameters['sendbirdChannelUrls'] != null) {
      queryParameters['sendbird_channel_urls'] =
        requestParameters['sendbirdChannelUrls'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/patients/list-via-post/`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PatientListViaPostToJSON(requestParameters['patientListViaPost']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaginatedPatientListFromJSON(jsonValue),
    );
  }

  /**
   */
  async patientPatientsListViaPostCreate(
    requestParameters: PatientPatientsListViaPostCreateRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaginatedPatientList> {
    const response = await this.patientPatientsListViaPostCreateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async patientPatientsPartialUpdateRaw(
    requestParameters: PatientPatientsPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Patient>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling patientPatientsPartialUpdate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/patients/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters['id'])),
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: PatchedPatientToJSON(requestParameters['patchedPatient']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PatientFromJSON(jsonValue),
    );
  }

  /**
   */
  async patientPatientsPartialUpdate(
    requestParameters: PatientPatientsPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Patient> {
    const response = await this.patientPatientsPartialUpdateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async patientPatientsRetrieveRaw(
    requestParameters: PatientPatientsRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Patient>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling patientPatientsRetrieve().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/patients/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters['id'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PatientFromJSON(jsonValue),
    );
  }

  /**
   */
  async patientPatientsRetrieve(
    requestParameters: PatientPatientsRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Patient> {
    const response = await this.patientPatientsRetrieveRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async patientPatientsUpdateRaw(
    requestParameters: PatientPatientsUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Patient>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling patientPatientsUpdate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/patients/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters['id'])),
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: PatientToJSON(requestParameters['patient']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PatientFromJSON(jsonValue),
    );
  }

  /**
   */
  async patientPatientsUpdate(
    requestParameters: PatientPatientsUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Patient> {
    const response = await this.patientPatientsUpdateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Enroll a new patient in RTM.  Responses: - 201: Patient enrolled in RTM - 400: Bad request - 402: Phone number already taken
   */
  async patientRtmEnrollCreateRaw(
    requestParameters: PatientRtmEnrollCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PatientRtmEnrollCreate201Response>> {
    if (requestParameters['patientCreate'] == null) {
      throw new runtime.RequiredError(
        'patientCreate',
        'Required parameter "patientCreate" was null or undefined when calling patientRtmEnrollCreate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/rtm-enroll/`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PatientCreateToJSON(requestParameters['patientCreate']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PatientRtmEnrollCreate201ResponseFromJSON(jsonValue),
    );
  }

  /**
   * Enroll a new patient in RTM.  Responses: - 201: Patient enrolled in RTM - 400: Bad request - 402: Phone number already taken
   */
  async patientRtmEnrollCreate(
    requestParameters: PatientRtmEnrollCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PatientRtmEnrollCreate201Response> {
    const response = await this.patientRtmEnrollCreateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async patientRtmReEnrollCreateRaw(
    requestParameters: PatientRtmReEnrollCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PatientRtmReEnrollCreate200Response>> {
    if (requestParameters['patientId'] == null) {
      throw new runtime.RequiredError(
        'patientId',
        'Required parameter "patientId" was null or undefined when calling patientRtmReEnrollCreate().',
      );
    }

    if (requestParameters['patientRTMEnrollment'] == null) {
      throw new runtime.RequiredError(
        'patientRTMEnrollment',
        'Required parameter "patientRTMEnrollment" was null or undefined when calling patientRtmReEnrollCreate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/rtm-re-enroll/{patient_id}`.replace(
          `{${'patient_id'}}`,
          encodeURIComponent(String(requestParameters['patientId'])),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PatientRTMEnrollmentToJSON(
          requestParameters['patientRTMEnrollment'],
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PatientRtmReEnrollCreate200ResponseFromJSON(jsonValue),
    );
  }

  /**
   */
  async patientRtmReEnrollCreate(
    requestParameters: PatientRtmReEnrollCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PatientRtmReEnrollCreate200Response> {
    const response = await this.patientRtmReEnrollCreateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Mixin to filter patients or users based on the provider\'s clinics. Also adds a custom permission to only allow providers to access the view.
   */
  async patientUpdateCareTeamPartialUpdateRaw(
    requestParameters: PatientUpdateCareTeamPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PatientCareTeam>> {
    if (requestParameters['userId'] == null) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter "userId" was null or undefined when calling patientUpdateCareTeamPartialUpdate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/update-care-team/{user_id}`.replace(
          `{${'user_id'}}`,
          encodeURIComponent(String(requestParameters['userId'])),
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: PatchedPatientCareTeamToJSON(
          requestParameters['patchedPatientCareTeam'],
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PatientCareTeamFromJSON(jsonValue),
    );
  }

  /**
   * Mixin to filter patients or users based on the provider\'s clinics. Also adds a custom permission to only allow providers to access the view.
   */
  async patientUpdateCareTeamPartialUpdate(
    requestParameters: PatientUpdateCareTeamPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PatientCareTeam> {
    const response = await this.patientUpdateCareTeamPartialUpdateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Mixin to filter patients or users based on the provider\'s clinics. Also adds a custom permission to only allow providers to access the view.
   */
  async patientUpdateCareTeamUpdateRaw(
    requestParameters: PatientUpdateCareTeamUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PatientCareTeam>> {
    if (requestParameters['userId'] == null) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter "userId" was null or undefined when calling patientUpdateCareTeamUpdate().',
      );
    }

    if (requestParameters['patientCareTeam'] == null) {
      throw new runtime.RequiredError(
        'patientCareTeam',
        'Required parameter "patientCareTeam" was null or undefined when calling patientUpdateCareTeamUpdate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/update-care-team/{user_id}`.replace(
          `{${'user_id'}}`,
          encodeURIComponent(String(requestParameters['userId'])),
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: PatientCareTeamToJSON(requestParameters['patientCareTeam']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PatientCareTeamFromJSON(jsonValue),
    );
  }

  /**
   * Mixin to filter patients or users based on the provider\'s clinics. Also adds a custom permission to only allow providers to access the view.
   */
  async patientUpdateCareTeamUpdate(
    requestParameters: PatientUpdateCareTeamUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PatientCareTeam> {
    const response = await this.patientUpdateCareTeamUpdateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}
