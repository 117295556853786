/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `active` - Active
 * * `inactive` - Inactive
 * @export
 */
export const ProviderPatientRelationshipStatusEnum = {
  Active: 'active',
  Inactive: 'inactive',
} as const;
export type ProviderPatientRelationshipStatusEnum =
  (typeof ProviderPatientRelationshipStatusEnum)[keyof typeof ProviderPatientRelationshipStatusEnum];

export function ProviderPatientRelationshipStatusEnumFromJSON(
  json: any,
): ProviderPatientRelationshipStatusEnum {
  return ProviderPatientRelationshipStatusEnumFromJSONTyped(json, false);
}

export function ProviderPatientRelationshipStatusEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ProviderPatientRelationshipStatusEnum {
  return json as ProviderPatientRelationshipStatusEnum;
}

export function ProviderPatientRelationshipStatusEnumToJSON(
  value?: ProviderPatientRelationshipStatusEnum | null,
): any {
  return value as any;
}
