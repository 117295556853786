/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `CASH_PAY_PATIENT` - Cash Pay Patient
 * * `NON_REIMBURSING_INS` - Non-Reimbursing Insurance
 * * `DISCHARGED_PATIENT` - Discharged Patient
 * * `OTHER` - Other
 * * `BLANK` - Blank
 * @export
 */
export const CancellationReasonTypeE44Enum = {
  CashPayPatient: 'CASH_PAY_PATIENT',
  NonReimbursingIns: 'NON_REIMBURSING_INS',
  DischargedPatient: 'DISCHARGED_PATIENT',
  Other: 'OTHER',
  Blank: 'BLANK',
} as const;
export type CancellationReasonTypeE44Enum =
  (typeof CancellationReasonTypeE44Enum)[keyof typeof CancellationReasonTypeE44Enum];

export function CancellationReasonTypeE44EnumFromJSON(
  json: any,
): CancellationReasonTypeE44Enum {
  return CancellationReasonTypeE44EnumFromJSONTyped(json, false);
}

export function CancellationReasonTypeE44EnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CancellationReasonTypeE44Enum {
  return json as CancellationReasonTypeE44Enum;
}

export function CancellationReasonTypeE44EnumToJSON(
  value?: CancellationReasonTypeE44Enum | null,
): any {
  return value as any;
}
