/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `gui_rf` - GUI_RF
 * * `gui_pef` - GUI_PEF
 * * `csv` - CSV
 * * `bridge` - BRIDGE
 * @export
 */
export const AccountOriginEnum = {
  GuiRf: 'gui_rf',
  GuiPef: 'gui_pef',
  Csv: 'csv',
  Bridge: 'bridge',
} as const;
export type AccountOriginEnum =
  (typeof AccountOriginEnum)[keyof typeof AccountOriginEnum];

export function AccountOriginEnumFromJSON(json: any): AccountOriginEnum {
  return AccountOriginEnumFromJSONTyped(json, false);
}

export function AccountOriginEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AccountOriginEnum {
  return json as AccountOriginEnum;
}

export function AccountOriginEnumToJSON(value?: AccountOriginEnum | null): any {
  return value as any;
}
