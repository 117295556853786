import { ConnectionState } from '@sendbird/chat';
import { UnreadChannelFilter } from '@sendbird/chat/groupChannel';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'sonner';

import { selectUserId, useMetadataState } from '@/state/metadata-state';
import { sendbirdChat, useSendbirdChatState } from '@/state/sendbird-state';
import { UnSerializedProvider } from '@/utils/auth-utils';
import { reportToSentry } from '@/utils/sentry';

export const useSendbirdConnectSync = () => {
  const userId = useMetadataState(selectUserId);

  // Redux state is not typed...
  const {
    api: { sendbird_session_token: sendbirdSessionToken },
  } = useSelector(({ Session }: { Session: UnSerializedProvider }) => Session);

  const connect = useSendbirdChatState((state) => state.connect);
  const disconnect = useSendbirdChatState((state) => state.disconnect);

  useEffect(() => {
    if (userId && sendbirdSessionToken) {
      connect(`${userId}`, sendbirdSessionToken);
    }

    return () => {
      disconnect();
    };
  }, [userId, sendbirdSessionToken, connect, disconnect]);
};

export const useSendbirdUnreadMessagesCount = () => {
  const userId = useMetadataState(selectUserId);

  const isConnected = useSendbirdChatState(
    (state) => state.connectionState === ConnectionState.OPEN,
  );

  return useQuery({
    queryKey: ['unreadMessages', userId],
    queryFn: async (): Promise<Record<string, number>> => {
      try {
        const query =
          await sendbirdChat.groupChannel.createMyGroupChannelListQuery({
            unreadChannelFilter: UnreadChannelFilter.UNREAD_MESSAGE,
          });

        const unreadChannels: Record<string, number> = {};

        while (query.hasNext) {
          const channels = await query.next();

          channels.forEach((channel) => {
            unreadChannels[channel.url] = channel.unreadMessageCount;
          });
        }

        return unreadChannels;
      } catch (error) {
        console.error('Error getting unread messages count:', error);

        toast.error('Error getting unread messages count', {
          description: (error as Error)?.message,
        });

        const errorWrapper = new Error('Error getting unread messages count');
        errorWrapper.name = 'SendbirdChatSDK';

        reportToSentry(errorWrapper, {
          error,
        });

        return {};
      }
    },
    initialData: {},
    enabled: isConnected,
  });
};
