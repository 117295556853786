/* tslint:disable */

/* eslint-disable */

/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface AbstractMetrics
 */
export interface AbstractMetrics {
  /**
   *
   * @type {number}
   * @memberof AbstractMetrics
   */
  readonly avgResponseRate: number;
  /**
   *
   * @type {number}
   * @memberof AbstractMetrics
   */
  enrollmentCount: number;
  /**
   *
   * @type {number}
   * @memberof AbstractMetrics
   */
  billingInstanceCount: number;
  /**
   *
   * @type {string}
   * @memberof AbstractMetrics
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AbstractMetrics
   */
  name: string;
}

/**
 * Check if a given object implements the AbstractMetrics interface.
 */
export function instanceOfAbstractMetrics(value: object): boolean {
  if (!('avgResponseRate' in value)) return false;
  if (!('enrollmentCount' in value)) return false;
  if (!('billingInstanceCount' in value)) return false;
  if (!('id' in value)) return false;
  if (!('name' in value)) return false;
  return true;
}

export function AbstractMetricsFromJSON(json: any): AbstractMetrics {
  return AbstractMetricsFromJSONTyped(json, false);
}

export function AbstractMetricsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AbstractMetrics {
  if (json == null) {
    return json;
  }
  return {
    avgResponseRate: json['avg_response_rate'],
    enrollmentCount: json['enrollment_count'],
    billingInstanceCount: json['billing_instance_count'],
    id: json['id'],
    name: json['name'],
  };
}

export function AbstractMetricsToJSON(value?: AbstractMetrics | null): any {
  if (value == null) {
    return value;
  }
  return {
    enrollment_count: value['enrollmentCount'],
    billing_instance_count: value['billingInstanceCount'],
    id: value['id'],
    name: value['name'],
  };
}
