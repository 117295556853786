/* tslint:disable */

/* eslint-disable */

/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface PatientCareTeam
 */
export interface PatientCareTeam {
  /**
   * The email of the primary provider.
   * @type {string}
   * @memberof PatientCareTeam
   */
  primaryProvider: string;
  /**
   * A list of emails of the secondary providers.
   * @type {Array<string>}
   * @memberof PatientCareTeam
   */
  secondaryProviders: Array<string>;
}

/**
 * Check if a given object implements the PatientCareTeam interface.
 */
export function instanceOfPatientCareTeam(value: object): boolean {
  if (!('primaryProvider' in value)) return false;
  if (!('secondaryProviders' in value)) return false;
  return true;
}

export function PatientCareTeamFromJSON(json: any): PatientCareTeam {
  return PatientCareTeamFromJSONTyped(json, false);
}

export function PatientCareTeamFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PatientCareTeam {
  if (json == null) {
    return json;
  }
  return {
    primaryProvider: json['primary_provider'],
    secondaryProviders: json['secondary_providers'],
  };
}

export function PatientCareTeamToJSON(value?: PatientCareTeam | null): any {
  if (value == null) {
    return value;
  }
  return {
    primary_provider: value['primaryProvider'],
    secondary_providers: value['secondaryProviders'],
  };
}
