import { Row } from '@tanstack/react-table';
import { isEmpty } from 'lodash-es';
import { InfoIcon } from 'lucide-react';

import { BillingInstance, PendingReasonsEnum, TypeAc3Enum } from '@/schema';
import { formatDateShort } from '@/utils/date-formatters';

import { Tooltip, TooltipContent, TooltipTrigger } from '../ui/tooltip';

type BillingRow = Row<BillingInstance>;

interface ReasonDescriptionProps {
  code: TypeAc3Enum;
  billableOn: Date;
  prevReconciled: Date;
}

const pendingReasonLabelEnum = {
  [PendingReasonsEnum.AwaitingCycleEnd]: {
    reason: 'Awaiting Cycle End',
    descrption: ({ code, billableOn }: ReasonDescriptionProps) =>
      `Connect code (9897${code === TypeAc3Enum.Connect ? '5' : '7'}) can only be billed upon cycle completion on ${formatDateShort(billableOn)}`,
  },
  [PendingReasonsEnum.ThirtyDaysSincePreviousRule]: {
    reason: '30-Days Rule',
    descrption: ({
      billableOn,
      prevReconciled,
    }: ReasonDescriptionProps) => `Connect code (98977) can only be billed once every 30 days.
    It has been used recently on ${formatDateShort(prevReconciled)}. It will be eligible for billing on ${formatDateShort(billableOn)}.`,
  },
  [PendingReasonsEnum.CalendarMonthNotEnded]: {
    reason: 'Monthly Reset',
    descrption: ({
      billableOn,
    }: ReasonDescriptionProps) => `Support Codes (90980/81) can only be submitted at the start of the next calendar month.
    It will eligible for billing on ${formatDateShort(billableOn)}`,
  },
  [PendingReasonsEnum.PriorBillingNotReconciled]: {
    reason: 'Prior Billing Pending',
    descrption:
      () => `SaRA has detected that a prior Engage Code (98977) was generated for this patient but not reconciled.
      The earlier billing unit must be reconciled before this new code is eligible for billing.`,
  },
} as const;

interface BillingPendingReasonCellProps {
  row: BillingRow;
}

export const BillingPendingReasonCell = ({
  row,
}: BillingPendingReasonCellProps) => {
  const {
    pendingReasons,
    type: code,
    billableOn,
    prevBillinginstance,
  } = row.original;

  if (isEmpty(pendingReasons)) {
    return (
      <span className="block max-w-40 leading-none">
        No Pending Reason, please contact support
      </span>
    );
  }

  const prevReconciled = prevBillinginstance?.reconciled ?? new Date();

  return (
    <Tooltip>
      <TooltipTrigger className="flex max-w-40 gap-2">
        <InfoIcon size={14} />

        <div className="flex flex-col gap-2">
          {pendingReasons?.map((reason) => (
            <span key={reason} className="leading-none">
              {pendingReasonLabelEnum[reason].reason}
            </span>
          ))}
        </div>
      </TooltipTrigger>

      <TooltipContent>
        <div className="flex flex-col gap-4">
          <p>Pending reason descriptions:</p>

          <ul className="ml-4 flex list-disc flex-col gap-2">
            {pendingReasons?.map((reason) => (
              <li key={reason}>
                {pendingReasonLabelEnum[reason].descrption({
                  code,
                  billableOn,
                  prevReconciled,
                })}
              </li>
            ))}
          </ul>
        </div>
      </TooltipContent>
    </Tooltip>
  );
};
