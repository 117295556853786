/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `DTR` - DATA_REVIEW
 * * `PHN` - PHONE_CALL
 * * `VID` - VIDEO_CALL
 * * `INP` - IN_PERSON_VISIT
 * @export
 */
export const ActivityTypesEnum = {
  Dtr: 'DTR',
  Phn: 'PHN',
  Vid: 'VID',
  Inp: 'INP',
} as const;
export type ActivityTypesEnum =
  (typeof ActivityTypesEnum)[keyof typeof ActivityTypesEnum];

export function ActivityTypesEnumFromJSON(json: any): ActivityTypesEnum {
  return ActivityTypesEnumFromJSONTyped(json, false);
}

export function ActivityTypesEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ActivityTypesEnum {
  return json as ActivityTypesEnum;
}

export function ActivityTypesEnumToJSON(value?: ActivityTypesEnum | null): any {
  return value as any;
}
