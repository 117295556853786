import {
  LDClient,
  LDContextCommon,
  LDMultiKindContext,
  useLDClient,
} from 'launchdarkly-react-client-sdk';
import { debounce } from 'lodash-es';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router';

import { useMetadataSync } from '@/hooks/use-metadata-sync';
import { useSendbirdConnectSync } from '@/hooks/use-sendbird-hooks';
import { useThemeSync } from '@/hooks/use-theme-sync';
import { SENDBIRD_APP_ID } from '@/old/config';
import {
  bootIntercom,
  hideIntercom,
  updateIntercom,
} from '@/old/utils/thirdPartyAPIs/intercom';
import { SegmentIO } from '@/old/utils/thirdPartyAPIs/segment';
import { Clinic, Company, UserSerializerSlim } from '@/schema';
import {
  selectCompany,
  selectDefaultClinic,
  selectUser,
  useMetadataState,
} from '@/state/metadata-state';
import { identifySentryUser } from '@/utils/sentry';

import { BRIDGE_SSO_URL } from './app-routes';

const initDependencies = debounce(
  async (
    ldClient?: LDClient,
    user?: UserSerializerSlim,
    company?: Company,
    clinic?: Clinic,
  ) => {
    try {
      const userContext = (ldClient?.getContext() as LDMultiKindContext)
        .user as LDContextCommon | undefined;

      if (user && userContext?.key === `user-${user.id}`) {
        return;
      }

      // Intercom reboots if the user changes, usually at login/logout
      bootIntercom();

      if (user && clinic && company) {
        identifySentryUser(user);
        await ldClient?.identify({
          kind: 'multi',
          user: {
            key: `user-${user.id}`,
            name: `${user.firstName} ${user.lastName}`,
            email: user.email,
          },
          company: {
            key: `company-${company.id}`,
            name: company.name,
            domain: company.domain,
          },
          clinic: {
            key: `clinic-${clinic.uuid}`,
            name: clinic.name,
            domain: clinic.domain,
          },
        });
      } else {
        identifySentryUser();
        await ldClient?.identify({ anonymous: true });
      }
    } catch (error) {
      console.error(error);
    }
  },
  1e3,
);

export const RootLayout = () => {
  useThemeSync();
  useMetadataSync();
  useSendbirdConnectSync();

  const ldClient = useLDClient();
  const location = useLocation();

  const clinic = useMetadataState(selectDefaultClinic);
  const company = useMetadataState(selectCompany);
  const user = useMetadataState(selectUser);

  useEffect(() => {
    initDependencies(ldClient, user, company, clinic);
  }, [clinic, company, ldClient, user]);

  useEffect(() => {
    SegmentIO.page();

    // Important: You will need to call Intercom("update") with some new user data in
    // order to initiate a "ping" every time a URL changes. If no user data has
    // changed you can send the current time as the value for 'last_request_at' like this:
    updateIntercom();

    if (location.pathname.includes(BRIDGE_SSO_URL)) {
      hideIntercom();
    }
  }, [location]);

  if (!SENDBIRD_APP_ID) {
    return <p>Set APP_ID in const.js</p>;
  }

  return <Outlet />;
};
