/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `A` - Active
 * * `P` - Paused
 * * `T` - Terminated
 * @export
 */
export const RTMEnrollmentStatusEnum = {
  A: 'A',
  P: 'P',
  T: 'T',
} as const;
export type RTMEnrollmentStatusEnum =
  (typeof RTMEnrollmentStatusEnum)[keyof typeof RTMEnrollmentStatusEnum];

export function RTMEnrollmentStatusEnumFromJSON(
  json: any,
): RTMEnrollmentStatusEnum {
  return RTMEnrollmentStatusEnumFromJSONTyped(json, false);
}

export function RTMEnrollmentStatusEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): RTMEnrollmentStatusEnum {
  return json as RTMEnrollmentStatusEnum;
}

export function RTMEnrollmentStatusEnumToJSON(
  value?: RTMEnrollmentStatusEnum | null,
): any {
  return value as any;
}
