/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const StatusB89Enum = {
  Elg: 'ELG',
  Blk: 'BLK',
  Rec: 'REC',
  Cnc: 'CNC',
} as const;
export type StatusB89Enum = (typeof StatusB89Enum)[keyof typeof StatusB89Enum];

export function StatusB89EnumFromJSON(json: any): StatusB89Enum {
  return StatusB89EnumFromJSONTyped(json, false);
}

export function StatusB89EnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): StatusB89Enum {
  return json as StatusB89Enum;
}

export function StatusB89EnumToJSON(value?: StatusB89Enum | null): any {
  return value as any;
}
