import { debounce } from 'lodash-es';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { PatientPatientsListRequest } from '@/schema';

export type SearchType = PatientPatientsListRequest['search'];

export interface FilterState {
  debounceSearch?: SearchType;
  providerId: null | string;
  search?: SearchType;
  hasUnreadMessages?: boolean;
  hasReviewInRange?: boolean;
  hasRepliesInRange?: boolean;
  hasSynchronousAction?: false;
  setDebounceSearch: (search: SearchType) => void;
  setProviderId: (providerId: 'null' | string) => void;
  setSearch: (search: SearchType) => void;
  setHasUnreadMessages: (hasUnreadMessages?: boolean) => void;
  setHasReviewInRange: (hasReviewInRange?: boolean) => void;
  setHasRepliesInRange: (hasRepliesInRange?: boolean) => void;
  setHasSynchronousAction: (hasSynchronousAction?: false) => void;
}

export const useFilterState = create<FilterState>()(
  devtools(
    persist(
      (set) =>
        ({
          providerId: null,
          setSearch: (search) => {
            set({ search });
            debounceSearch(search);
          },
          setDebounceSearch: (debounceSearch) => set({ debounceSearch }),
          setProviderId: (providerId) => set({ providerId }),
          setHasUnreadMessages: (hasUnreadMessages) =>
            set({ hasUnreadMessages }),
          setHasReviewInRange: (hasReviewInRange) => set({ hasReviewInRange }),
          setHasRepliesInRange: (hasRepliesInRange) =>
            set({ hasRepliesInRange }),
          setHasSynchronousAction: (hasSynchronousAction) =>
            set({ hasSynchronousAction }),
        }) as FilterState,
      { name: 'filter-state' },
    ),
    { name: 'filter-state' },
  ),
);

const setDebounceSearch = useFilterState.getState().setDebounceSearch;

const debounceSearch = debounce((search) => {
  setDebounceSearch(search);
}, 500);

export const selectIsSearchActive = (state: FilterState) =>
  (state.debounceSearch?.length ?? 0) > 0;

export const selectHasUnreadMessages = (state: FilterState) =>
  state.hasUnreadMessages;
export const selectSetHasUnreadMessages = (state: FilterState) =>
  state.setHasUnreadMessages;

export const selectHasReviewInRange = (state: FilterState) =>
  state.hasReviewInRange;
export const selectSetHasReviewInRange = (state: FilterState) =>
  state.setHasReviewInRange;

export const selectHasRepliesInRange = (state: FilterState) =>
  state.hasRepliesInRange;
export const selectSetHasRepliesInRange = (state: FilterState) =>
  state.setHasRepliesInRange;

export const selectHasSynchronousAction = (state: FilterState) =>
  state.hasSynchronousAction;
export const selectSetHasSynchronousAction = (state: FilterState) =>
  state.setHasSynchronousAction;
