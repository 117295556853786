/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `current_week` - Current week
 * * `last_week` - Last week
 * * `last_month` - Last month
 * * `current_month` - Current month
 * * `all_dates` - All dates
 * * `365_day` - 365 day
 * * `180_day` - 180 day
 * * `90_day` - 90 day
 * * `60_day` - 60 day
 * * `30_day` - 30 day
 * * `14_day` - 14 day
 * * `7_day` - 7 day
 * * `3_day` - 3 day
 * * `1_day` - 1 day
 * @export
 */
export const DateRangeEnum = {
  CurrentWeek: 'current_week',
  LastWeek: 'last_week',
  LastMonth: 'last_month',
  CurrentMonth: 'current_month',
  AllDates: 'all_dates',
  _365Day: '365_day',
  _180Day: '180_day',
  _90Day: '90_day',
  _60Day: '60_day',
  _30Day: '30_day',
  _14Day: '14_day',
  _7Day: '7_day',
  _3Day: '3_day',
  _1Day: '1_day',
} as const;
export type DateRangeEnum = (typeof DateRangeEnum)[keyof typeof DateRangeEnum];

export function DateRangeEnumFromJSON(json: any): DateRangeEnum {
  return DateRangeEnumFromJSONTyped(json, false);
}

export function DateRangeEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): DateRangeEnum {
  return json as DateRangeEnum;
}

export function DateRangeEnumToJSON(value?: DateRangeEnum | null): any {
  return value as any;
}
