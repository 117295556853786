import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  BarChart2Icon,
  CircleHelpIcon,
  CircleUserIcon,
  GraduationCapIcon,
  LogOutIcon,
  MenuIcon,
  MoonIcon,
  SunIcon,
  UsersRoundIcon,
  WalletIcon,
} from 'lucide-react';
import { useDispatch } from 'react-redux';
import { Link, NavLink, To } from 'react-router';

import LogoImage from '@/assets/images/sara-logo.png';
import { logout } from '@/old/state/actions/Login/login_actions';
import {
  BILLING_URL,
  METRICS_URL,
  PATIENT_LIST_URL,
  SUPPORT_URL,
} from '@/routes/app-routes';
import { useBillingState } from '@/state/billing-state';
import { useFilterState } from '@/state/filter-state';
import { usePatientListState } from '@/state/patient-list-state';
import { useTableState } from '@/state/table-state';
import {
  ThemeIconsEnum,
  ThemeLabelsEnum,
  ThemesEnum,
  useThemeState,
} from '@/state/theme-state';

import { Button } from './ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from './ui/dropdown-menu';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from './ui/sheet';

const clearStores = () => {
  useTableState.persist.clearStorage();
  useFilterState.persist.clearStorage();
  useBillingState.persist.clearStorage();
  usePatientListState.persist.clearStorage();
};

interface SheetNavLinkProps {
  'aria-label': string;
  children: React.ReactNode;
  to: To;
}

const SheetNavLink = ({
  'aria-label': ariaLabel,
  children,
  to,
}: SheetNavLinkProps) => (
  <NavLink to={to} aria-label={ariaLabel}>
    {({ isActive }) => (
      <Button
        variant={isActive ? 'secondary' : 'ghost'}
        className="flex w-full justify-start gap-2"
      >
        {children}
      </Button>
    )}
  </NavLink>
);

export const AppHeader = () => {
  const { billingUi, inactivityLogoutEnabled, metricsPage } = useFlags();
  const { theme, setTheme } = useThemeState();
  const dispatch = useDispatch();

  const ThemeIcon = ThemeIconsEnum[theme];

  const handleLogout = () => {
    clearStores();
    // TODO: Remove redux dependency eventually
    dispatch(logout());
  };

  const inactivityLogout = () => {
    const storageSession = localStorage.getItem('persist:root');
    if (!storageSession) {
      return;
    }
    const sessionItem = JSON.parse(storageSession);

    const session = JSON.parse(sessionItem.Session);
    session.token = session.token.slice(1);

    sessionItem.Session = JSON.stringify(session);
    localStorage.setItem('persist:root', JSON.stringify(sessionItem));

    window.location.reload();
  };

  return (
    <header
      data-testid="app-header"
      className="sticky top-0 flex w-full border bg-card p-2 md:justify-between"
    >
      <Sheet>
        <SheetTrigger asChild>
          <Button variant="ghost" size="icon" className="shrink-0 md:hidden">
            <MenuIcon className="h-5 w-5" />
            <span className="sr-only">Toggle navigation menu</span>
          </Button>
        </SheetTrigger>

        <SheetContent side="left">
          <SheetHeader className="hidden">
            <SheetTitle>Navigation Menu</SheetTitle>
            <SheetDescription>List of pages to navigate</SheetDescription>
          </SheetHeader>

          <nav
            className="grid gap-2 text-lg font-medium"
            role="navigation"
            aria-label="Main navigation"
          >
            <NavLink
              to="/"
              // Force reload countermeasure to 3rd party auth issues: https://sara-health.slack.com/archives/C6B5YB59A/p1715726114979829?thread_ts=1715725325.303859&cid=C6B5YB59A
              reloadDocument
              className="flex items-center gap-2 md:gap-4"
              aria-label="Home route"
              onClick={() => clearStores()}
            >
              <img src={LogoImage} alt="logo" className="h-10 w-10" />
            </NavLink>

            <SheetNavLink to={PATIENT_LIST_URL} aria-label="Patients route">
              <UsersRoundIcon />

              <h2 className="scroll-m-20 text-xl font-medium tracking-widest">
                Patients
              </h2>
            </SheetNavLink>

            {billingUi && (
              <SheetNavLink to={BILLING_URL} aria-label="Billing route">
                <WalletIcon />

                <h2 className="scroll-m-20 text-xl font-medium tracking-widest">
                  Billing
                </h2>
              </SheetNavLink>
            )}

            {metricsPage && (
              <SheetNavLink to={METRICS_URL} aria-label="Metrics route">
                <BarChart2Icon />

                <h2 className="scroll-m-20 text-xl font-medium tracking-widest">
                  Metrics
                </h2>
              </SheetNavLink>
            )}
          </nav>
        </SheetContent>
      </Sheet>

      <nav
        className="flex gap-4 max-md:grow"
        role="navigation"
        aria-label="Home navigation"
      >
        <Link
          to="/"
          // Force reload countermeasure to 3rd party auth issues: https://sara-health.slack.com/archives/C6B5YB59A/p1715726114979829?thread_ts=1715725325.303859&cid=C6B5YB59A
          reloadDocument
          className="flex items-center gap-2 md:gap-4"
          aria-label="Home route"
          onClick={() => clearStores()}
        >
          <div className="flex h-10 w-10 justify-center">
            <img src={LogoImage} alt="SaRA logo" />
          </div>

          <h1 className="scroll-m-20 text-2xl font-medium tracking-widest">
            SaRA HEALTH
          </h1>
        </Link>
      </nav>

      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="icon" className="rounded-full">
            <CircleUserIcon className="h-5 w-5" />
            <span className="sr-only">Toggle user menu</span>
          </Button>
        </DropdownMenuTrigger>

        <DropdownMenuContent align="end">
          <Link
            to="https://sarahealth.notion.site/SaRA-Academy-e9f44f543a214d53a971eeefa1cf3c34"
            target="_blank"
            rel="noopener noreferrer"
          >
            <DropdownMenuItem className="flex gap-4">
              <GraduationCapIcon size={18} className="opacity-50" />
              SaRA Academy
            </DropdownMenuItem>
          </Link>

          <DropdownMenuSub>
            <DropdownMenuSubTrigger className="flex gap-4">
              <ThemeIcon size={18} className="opacity-50" />
              {ThemeLabelsEnum[theme]} Theme
            </DropdownMenuSubTrigger>

            <DropdownMenuPortal>
              <DropdownMenuSubContent>
                <DropdownMenuItem
                  className="flex gap-4"
                  onClick={() => setTheme(ThemesEnum.light)}
                >
                  <SunIcon size={18} className="opacity-50" />
                  Light Mode
                </DropdownMenuItem>
                <DropdownMenuItem
                  className="flex gap-4"
                  onClick={() => setTheme(ThemesEnum.dark)}
                >
                  <MoonIcon size={18} className="opacity-50" />
                  Dark Mode
                </DropdownMenuItem>
                {/* <DropdownMenuItem
                  className="flex gap-4"
                  onClick={() => setTheme(ThemesEnum.system)}
                >
                  <SunMoon />
                  System Mode
                </DropdownMenuItem> */}
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
          </DropdownMenuSub>
          <Link to={SUPPORT_URL}>
            <DropdownMenuItem className="flex gap-4">
              <CircleHelpIcon size={18} className="opacity-50" />
              Support
            </DropdownMenuItem>
          </Link>
          <DropdownMenuItem className="flex gap-4" onClick={handleLogout}>
            <LogOutIcon size={18} className="opacity-50" />
            Logout
          </DropdownMenuItem>
          {inactivityLogoutEnabled && (
            <DropdownMenuItem className="flex gap-4" onClick={inactivityLogout}>
              <LogOutIcon size={18} className="opacity-50" />
              Inactivity Logout
            </DropdownMenuItem>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </header>
  );
};
