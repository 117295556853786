/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `aa` - Afar
 * * `ab` - Abkhazian
 * * `af` - Afrikaans
 * * `ak` - Akan
 * * `am` - Amharic
 * * `ar` - Arabic
 * * `an` - Aragonese
 * * `as` - Assamese
 * * `av` - Avaric
 * * `ae` - Avestan
 * * `ay` - Aymara
 * * `az` - Azerbaijani
 * * `ba` - Bashkir
 * * `bm` - Bambara
 * * `be` - Belarusian
 * * `bn` - Bengali
 * * `bi` - Bislama
 * * `bo` - Tibetan
 * * `bs` - Bosnian
 * * `br` - Breton
 * * `bg` - Bulgarian
 * * `ca` - Catalan
 * * `cs` - Czech
 * * `ch` - Chamorro
 * * `ce` - Chechen
 * * `cu` - Church Slavic
 * * `cv` - Chuvash
 * * `kw` - Cornish
 * * `co` - Corsican
 * * `cr` - Cree
 * * `cy` - Welsh
 * * `da` - Danish
 * * `de` - German
 * * `dv` - Dhivehi
 * * `dz` - Dzongkha
 * * `el` - Modern Greek (1453-)
 * * `en` - English
 * * `eo` - Esperanto
 * * `et` - Estonian
 * * `eu` - Basque
 * * `ee` - Ewe
 * * `fo` - Faroese
 * * `fa` - Persian
 * * `fj` - Fijian
 * * `fi` - Finnish
 * * `fr` - French
 * * `fy` - Western Frisian
 * * `ff` - Fulah
 * * `gd` - Scottish Gaelic
 * * `ga` - Irish
 * * `gl` - Galician
 * * `gv` - Manx
 * * `gn` - Guarani
 * * `gu` - Gujarati
 * * `ht` - Haitian
 * * `ha` - Hausa
 * * `sh` - Serbo-Croatian
 * * `he` - Hebrew
 * * `hz` - Herero
 * * `hi` - Hindi
 * * `ho` - Hiri Motu
 * * `hr` - Croatian
 * * `hu` - Hungarian
 * * `hy` - Armenian
 * * `ig` - Igbo
 * * `io` - Ido
 * * `ii` - Sichuan Yi
 * * `iu` - Inuktitut
 * * `ie` - Interlingue
 * * `ia` - Interlingua (International Auxiliary Language Association)
 * * `id` - Indonesian
 * * `ik` - Inupiaq
 * * `is` - Icelandic
 * * `it` - Italian
 * * `jv` - Javanese
 * * `ja` - Japanese
 * * `kl` - Kalaallisut
 * * `kn` - Kannada
 * * `ks` - Kashmiri
 * * `ka` - Georgian
 * * `kr` - Kanuri
 * * `kk` - Kazakh
 * * `km` - Khmer
 * * `ki` - Kikuyu
 * * `rw` - Kinyarwanda
 * * `ky` - Kirghiz
 * * `kv` - Komi
 * * `kg` - Kongo
 * * `ko` - Korean
 * * `kj` - Kuanyama
 * * `ku` - Kurdish
 * * `lo` - Lao
 * * `la` - Latin
 * * `lv` - Latvian
 * * `li` - Limburgan
 * * `ln` - Lingala
 * * `lt` - Lithuanian
 * * `lb` - Luxembourgish
 * * `lu` - Luba-Katanga
 * * `lg` - Ganda
 * * `mh` - Marshallese
 * * `ml` - Malayalam
 * * `mr` - Marathi
 * * `mk` - Macedonian
 * * `mg` - Malagasy
 * * `mt` - Maltese
 * * `mn` - Mongolian
 * * `mi` - Maori
 * * `ms` - Malay (macrolanguage)
 * * `my` - Burmese
 * * `na` - Nauru
 * * `nv` - Navajo
 * * `nr` - South Ndebele
 * * `nd` - North Ndebele
 * * `ng` - Ndonga
 * * `ne` - Nepali (macrolanguage)
 * * `nl` - Dutch
 * * `nn` - Norwegian Nynorsk
 * * `nb` - Norwegian Bokmål
 * * `no` - Norwegian
 * * `ny` - Nyanja
 * * `oc` - Occitan (post 1500)
 * * `oj` - Ojibwa
 * * `or` - Oriya (macrolanguage)
 * * `om` - Oromo
 * * `os` - Ossetian
 * * `pa` - Panjabi
 * * `pi` - Pali
 * * `pl` - Polish
 * * `pt` - Portuguese
 * * `ps` - Pushto
 * * `qu` - Quechua
 * * `rm` - Romansh
 * * `ro` - Romanian
 * * `rn` - Rundi
 * * `ru` - Russian
 * * `sg` - Sango
 * * `sa` - Sanskrit
 * * `si` - Sinhala
 * * `sk` - Slovak
 * * `sl` - Slovenian
 * * `se` - Northern Sami
 * * `sm` - Samoan
 * * `sn` - Shona
 * * `sd` - Sindhi
 * * `so` - Somali
 * * `st` - Southern Sotho
 * * `es` - Spanish
 * * `sq` - Albanian
 * * `sc` - Sardinian
 * * `sr` - Serbian
 * * `ss` - Swati
 * * `su` - Sundanese
 * * `sw` - Swahili (macrolanguage)
 * * `sv` - Swedish
 * * `ty` - Tahitian
 * * `ta` - Tamil
 * * `tt` - Tatar
 * * `te` - Telugu
 * * `tg` - Tajik
 * * `tl` - Tagalog
 * * `th` - Thai
 * * `ti` - Tigrinya
 * * `to` - Tonga (Tonga Islands)
 * * `tn` - Tswana
 * * `ts` - Tsonga
 * * `tk` - Turkmen
 * * `tr` - Turkish
 * * `tw` - Twi
 * * `ug` - Uighur
 * * `uk` - Ukrainian
 * * `ur` - Urdu
 * * `uz` - Uzbek
 * * `ve` - Venda
 * * `vi` - Vietnamese
 * * `vo` - Volapük
 * * `wa` - Walloon
 * * `wo` - Wolof
 * * `xh` - Xhosa
 * * `yi` - Yiddish
 * * `yo` - Yoruba
 * * `za` - Zhuang
 * * `zh` - Chinese
 * * `zu` - Zulu
 * @export
 */
export const LanguagesEnum = {
  Aa: 'aa',
  Ab: 'ab',
  Af: 'af',
  Ak: 'ak',
  Am: 'am',
  Ar: 'ar',
  An: 'an',
  As: 'as',
  Av: 'av',
  Ae: 'ae',
  Ay: 'ay',
  Az: 'az',
  Ba: 'ba',
  Bm: 'bm',
  Be: 'be',
  Bn: 'bn',
  Bi: 'bi',
  Bo: 'bo',
  Bs: 'bs',
  Br: 'br',
  Bg: 'bg',
  Ca: 'ca',
  Cs: 'cs',
  Ch: 'ch',
  Ce: 'ce',
  Cu: 'cu',
  Cv: 'cv',
  Kw: 'kw',
  Co: 'co',
  Cr: 'cr',
  Cy: 'cy',
  Da: 'da',
  De: 'de',
  Dv: 'dv',
  Dz: 'dz',
  El: 'el',
  En: 'en',
  Eo: 'eo',
  Et: 'et',
  Eu: 'eu',
  Ee: 'ee',
  Fo: 'fo',
  Fa: 'fa',
  Fj: 'fj',
  Fi: 'fi',
  Fr: 'fr',
  Fy: 'fy',
  Ff: 'ff',
  Gd: 'gd',
  Ga: 'ga',
  Gl: 'gl',
  Gv: 'gv',
  Gn: 'gn',
  Gu: 'gu',
  Ht: 'ht',
  Ha: 'ha',
  Sh: 'sh',
  He: 'he',
  Hz: 'hz',
  Hi: 'hi',
  Ho: 'ho',
  Hr: 'hr',
  Hu: 'hu',
  Hy: 'hy',
  Ig: 'ig',
  Io: 'io',
  Ii: 'ii',
  Iu: 'iu',
  Ie: 'ie',
  Ia: 'ia',
  Id: 'id',
  Ik: 'ik',
  Is: 'is',
  It: 'it',
  Jv: 'jv',
  Ja: 'ja',
  Kl: 'kl',
  Kn: 'kn',
  Ks: 'ks',
  Ka: 'ka',
  Kr: 'kr',
  Kk: 'kk',
  Km: 'km',
  Ki: 'ki',
  Rw: 'rw',
  Ky: 'ky',
  Kv: 'kv',
  Kg: 'kg',
  Ko: 'ko',
  Kj: 'kj',
  Ku: 'ku',
  Lo: 'lo',
  La: 'la',
  Lv: 'lv',
  Li: 'li',
  Ln: 'ln',
  Lt: 'lt',
  Lb: 'lb',
  Lu: 'lu',
  Lg: 'lg',
  Mh: 'mh',
  Ml: 'ml',
  Mr: 'mr',
  Mk: 'mk',
  Mg: 'mg',
  Mt: 'mt',
  Mn: 'mn',
  Mi: 'mi',
  Ms: 'ms',
  My: 'my',
  Na: 'na',
  Nv: 'nv',
  Nr: 'nr',
  Nd: 'nd',
  Ng: 'ng',
  Ne: 'ne',
  Nl: 'nl',
  Nn: 'nn',
  Nb: 'nb',
  No: 'no',
  Ny: 'ny',
  Oc: 'oc',
  Oj: 'oj',
  Or: 'or',
  Om: 'om',
  Os: 'os',
  Pa: 'pa',
  Pi: 'pi',
  Pl: 'pl',
  Pt: 'pt',
  Ps: 'ps',
  Qu: 'qu',
  Rm: 'rm',
  Ro: 'ro',
  Rn: 'rn',
  Ru: 'ru',
  Sg: 'sg',
  Sa: 'sa',
  Si: 'si',
  Sk: 'sk',
  Sl: 'sl',
  Se: 'se',
  Sm: 'sm',
  Sn: 'sn',
  Sd: 'sd',
  So: 'so',
  St: 'st',
  Es: 'es',
  Sq: 'sq',
  Sc: 'sc',
  Sr: 'sr',
  Ss: 'ss',
  Su: 'su',
  Sw: 'sw',
  Sv: 'sv',
  Ty: 'ty',
  Ta: 'ta',
  Tt: 'tt',
  Te: 'te',
  Tg: 'tg',
  Tl: 'tl',
  Th: 'th',
  Ti: 'ti',
  To: 'to',
  Tn: 'tn',
  Ts: 'ts',
  Tk: 'tk',
  Tr: 'tr',
  Tw: 'tw',
  Ug: 'ug',
  Uk: 'uk',
  Ur: 'ur',
  Uz: 'uz',
  Ve: 've',
  Vi: 'vi',
  Vo: 'vo',
  Wa: 'wa',
  Wo: 'wo',
  Xh: 'xh',
  Yi: 'yi',
  Yo: 'yo',
  Za: 'za',
  Zh: 'zh',
  Zu: 'zu',
} as const;
export type LanguagesEnum = (typeof LanguagesEnum)[keyof typeof LanguagesEnum];

export function LanguagesEnumFromJSON(json: any): LanguagesEnum {
  return LanguagesEnumFromJSONTyped(json, false);
}

export function LanguagesEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): LanguagesEnum {
  return json as LanguagesEnum;
}

export function LanguagesEnumToJSON(value?: LanguagesEnum | null): any {
  return value as any;
}
