/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `AWAITING_CYCLE_END` - Awaiting Cycle End
 * * `THIRTY_DAYS_SINCE_PREVIOUS_RULE` - 30 Days Since Previous Rule
 * * `CALENDAR_MONTH_NOT_ENDED` - Calendar Month Not Ended
 * * `PRIOR_BILLING_NOT_RECONCILED` - Prior Billing Not Reconciled
 * @export
 */
export const PendingReasonsEnum = {
  AwaitingCycleEnd: 'AWAITING_CYCLE_END',
  ThirtyDaysSincePreviousRule: 'THIRTY_DAYS_SINCE_PREVIOUS_RULE',
  CalendarMonthNotEnded: 'CALENDAR_MONTH_NOT_ENDED',
  PriorBillingNotReconciled: 'PRIOR_BILLING_NOT_RECONCILED',
} as const;
export type PendingReasonsEnum =
  (typeof PendingReasonsEnum)[keyof typeof PendingReasonsEnum];

export function PendingReasonsEnumFromJSON(json: any): PendingReasonsEnum {
  return PendingReasonsEnumFromJSONTyped(json, false);
}

export function PendingReasonsEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PendingReasonsEnum {
  return json as PendingReasonsEnum;
}

export function PendingReasonsEnumToJSON(
  value?: PendingReasonsEnum | null,
): any {
  return value as any;
}
