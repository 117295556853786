import { DialogProps } from '@radix-ui/react-dialog';
import { useControllableState } from '@radix-ui/react-use-controllable-state';
import { useMutation } from '@tanstack/react-query';
import { LoaderCircleIcon } from 'lucide-react';
import { useId } from 'react';
import { DefaultValues, FieldValues, UseFormReturn } from 'react-hook-form';
import { toast } from 'sonner';

import { handleResponseError } from '@/models/ErrorPayload';

import { ConfirmationDialog } from './confirmation-dialog';
import { Button } from './ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from './ui/dialog';
import { Form } from './ui/form';

interface HandlerParams {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

export interface FormDialogProps<T extends FieldValues = FieldValues>
  extends React.PropsWithChildren {
  dialogTitle: React.ReactNode;
  dialogDescription?: React.ReactNode;
  form: UseFormReturn<T>;
  defaultValues: DefaultValues<T>;
  submitLabel?: string;
  submitProgressLabel?: string;
  cancelLabel?: string;
  showDelete?: boolean;
  deleteLabel?: string;
  deleteProgressLabel?: string;
  deleteConfirmTitle?: string;
  deleteConfirmDescription?: string;
  errorToastLabel?: string;
  triggerElement: React.ReactNode;

  open?: DialogProps['open'];
  defaultOpen?: DialogProps['defaultOpen'];
  onOpenChange?: DialogProps['onOpenChange'];

  submitHandler: (
    params: {
      data: T;
    } & HandlerParams,
  ) => Promise<void>;
  deleteHandler?: (params: HandlerParams) => Promise<void>;
}

export const FormDialog = <T extends FieldValues>(
  props: FormDialogProps<T>,
) => {
  const {
    children,
    dialogTitle,
    dialogDescription,
    form,
    defaultValues,
    submitLabel = 'Submit',
    submitProgressLabel = 'Submitting',
    cancelLabel = 'Cancel',
    showDelete = false,
    deleteLabel = 'Delete',
    deleteProgressLabel = 'Deleting',
    deleteConfirmTitle = 'Are you sure?',
    deleteConfirmDescription = 'This action cannot be undone',
    errorToastLabel = 'Request failed',
    triggerElement,
    open,
    defaultOpen,
    onOpenChange,
    submitHandler,
    deleteHandler,
  } = props;

  const id = useId();

  const [isOpen = false, setIsOpen] = useControllableState({
    prop: open,
    defaultProp: defaultOpen,
    onChange: onOpenChange,
  });

  const { isPending, mutate } = useMutation({
    mutationFn: async (params: Parameters<typeof submitHandler>[0]) => {
      try {
        await submitHandler(params);
      } catch (error) {
        if (await handleResponseError(error, errorToastLabel)) {
          return;
        }

        toast.error(errorToastLabel, {
          description: 'Unidentified error, please contact support',
        });
      }
    },
  });

  const { isPending: isDeletePending, mutate: handleDelete } = useMutation({
    onMutate: async (params: HandlerParams) => {
      try {
        await deleteHandler?.(params);
      } catch (error) {
        if (await handleResponseError(error, errorToastLabel)) {
          return;
        }

        toast.error(errorToastLabel, {
          description: 'Unidentified error, please contact support',
        });
      }
    },
  });

  const onSubmit = async (data: T) => {
    mutate({ data, setIsOpen });
  };

  const onDelete = async () => {
    handleDelete({ setIsOpen });
  };

  return (
    <Form {...form}>
      <Dialog
        open={isOpen}
        onOpenChange={(open) => {
          setIsOpen(open);
          if (open) {
            form.reset(defaultValues);
          } else {
            form.reset();
          }
          onOpenChange?.(open);
        }}
      >
        <DialogTrigger onClick={() => setIsOpen(true)} asChild>
          {triggerElement}
        </DialogTrigger>

        <DialogContent>
          <DialogHeader>
            <DialogTitle>{dialogTitle}</DialogTitle>
            {DialogDescription && (
              <DialogDescription>{dialogDescription}</DialogDescription>
            )}
          </DialogHeader>

          <form
            id={`form-dialog-${id}`}
            className="flex flex-col gap-4"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            {children}
          </form>

          <DialogFooter className="gap-2">
            {showDelete && (
              <ConfirmationDialog
                title={deleteConfirmTitle}
                description={deleteConfirmDescription}
                onConfirm={onDelete}
              >
                <Button variant="destructive" className="sm:mr-auto">
                  {isDeletePending ? (
                    <>
                      <LoaderCircleIcon className="animate-spin" />{' '}
                      {deleteProgressLabel}
                    </>
                  ) : (
                    deleteLabel
                  )}
                </Button>
              </ConfirmationDialog>
            )}

            <Button
              type="submit"
              form={`form-dialog-${id}`}
              className="flex gap-2"
              disabled={isPending}
            >
              {isPending ? (
                <>
                  <LoaderCircleIcon className="animate-spin" />{' '}
                  {submitProgressLabel}
                </>
              ) : (
                submitLabel
              )}
            </Button>

            <DialogClose asChild>
              <Button variant="ghost">{cancelLabel}</Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </Form>
  );
};
