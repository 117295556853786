import {
  CONFIRM_PASSWORD,
  NEW_PASSWORD,
  RESET_PASSWORD,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_PENDING,
  RESET_PASSWORD_SUCCESS,
  SUBMIT_PASSWORD,
} from '@/old/state/actions/types';

const initialState = {
  email: '',
  resetPending: false,
  resetError: null,
  resetSuccess: null,
  newPassword: '',
  confirmPassword: '',
  submitPassword: null,
};
export const Reset = (state = initialState, action) => {
  switch (action.type) {
    case NEW_PASSWORD:
      return { ...state, ...action.payload };

    case CONFIRM_PASSWORD:
      return { ...state, ...action.payload };

    case SUBMIT_PASSWORD:
      return { ...state, ...action.payload };

    case RESET_PASSWORD:
      return { ...state, ...action.payload };

    case RESET_PASSWORD_ERROR:
      return { ...state, ...action.payload };

    case RESET_PASSWORD_PENDING:
      return { ...state, ...action.payload };

    case RESET_PASSWORD_SUCCESS:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};
