import { useEffect, useState } from 'react';

export const SCREENS = {
  'max-sm': '(max-width: 639px)',
  sm: '(min-width: 640px)',
  'max-md': '(max-width: 767px)',
  md: '(min-width: 768px)',
  'max-lg': '(max-width: 1023px)',
  lg: '(min-width: 1024px)',
  'max-xl': '(max-width: 1279px)',
  xl: '(min-width: 1280px)',
  'max-2xl': '(max-width: 1535px)',
  '2xl': '(min-width: 1536px)',
} as const;

/**
 * Custom hook that returns a boolean indicating if the media query matches
 * @param query The media query to check
 * @param delay Optional delay in milliseconds to wait before updating the state
 * @returns boolean indicating if the media query matches
 */
export const useMediaQuery = (query: string, delay?: number): boolean => {
  const [matches, setMatches] = useState<boolean>(false);

  useEffect(() => {
    const mediaQuery = matchMedia(query);

    setMatches(mediaQuery.matches);

    const onMatchChange = (event: MediaQueryListEvent) => {
      if (delay) {
        setTimeout(() => setMatches(event.matches), delay);
      } else {
        setMatches(event.matches);
      }
    };

    mediaQuery.addEventListener('change', onMatchChange);

    return () => {
      mediaQuery.removeEventListener('change', onMatchChange);
    };
  }, [query, delay]);

  return matches;
};
