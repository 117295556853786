/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `CASH_PAY_PATIENT` - CASH_PAY_PATIENT
 * * `NON_REIMBURSING_INS` - NON_REIMBURSING_INS
 * * `DISCHARGED_PATIENT` - DISCHARGED_PATIENT
 * * `OTHER` - OTHER
 * * `BLANK` - BLANK
 * @export
 */
export const BillingInstanceBulkCancelCancellationReasonTypeEnum = {
  CashPayPatient: 'CASH_PAY_PATIENT',
  NonReimbursingIns: 'NON_REIMBURSING_INS',
  DischargedPatient: 'DISCHARGED_PATIENT',
  Other: 'OTHER',
  Blank: 'BLANK',
} as const;
export type BillingInstanceBulkCancelCancellationReasonTypeEnum =
  (typeof BillingInstanceBulkCancelCancellationReasonTypeEnum)[keyof typeof BillingInstanceBulkCancelCancellationReasonTypeEnum];

export function BillingInstanceBulkCancelCancellationReasonTypeEnumFromJSON(
  json: any,
): BillingInstanceBulkCancelCancellationReasonTypeEnum {
  return BillingInstanceBulkCancelCancellationReasonTypeEnumFromJSONTyped(
    json,
    false,
  );
}

export function BillingInstanceBulkCancelCancellationReasonTypeEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BillingInstanceBulkCancelCancellationReasonTypeEnum {
  return json as BillingInstanceBulkCancelCancellationReasonTypeEnum;
}

export function BillingInstanceBulkCancelCancellationReasonTypeEnumToJSON(
  value?: BillingInstanceBulkCancelCancellationReasonTypeEnum | null,
): any {
  return value as any;
}
